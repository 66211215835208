<template>
    <div>
        <Navbar page="Editar atividade de mapeamento" link="/mapeamento" nameLink="Mapeamento de atividades de tratamento" />

        <!--fixed-->
        <div class="grid grid-cols-12 gap-5 bg-gray-200 p-2 w-full z-10">
            <div class="col-span-12 lg:col-span-3 flex flex-col text-xl ml-2">
                <div>Resumo da atividade:</div>
                <div>Data do preenchimento / atualização:</div>
                <input disabled v-model="form.dataAtualizacao" type="date" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" />
            </div>
            <div class="col-span-12 lg:col-span-4 flex">
                <ul>
                    <li>
                        <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                        <b>Data: </b>{{ dateFrom_id(form._id) }}
                    </li>
                    <li>
                        <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                        <b>Atividade: </b>{{ form.nome }}
                    </li>
                    <li>
                        <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                        <b>Setor: </b>{{  getNome(form.setor, setores) }}
                    </li>
                    <li>
                        <div class="flex flex-row">
                            <label for="ref" class="place-content-center mr-1 mt-1">Ref</label>
                            <input type="number" v-model="form.ref" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-span-12 lg:col-span-5 flex">
                <ul>
                    <li>
                        <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                        <b>Agente de tratamento: </b>{{ getNome(form.agente, agentesTratamento) }}
                    </li>
                    <li>
                        <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                        <b>Responsável pela atividade: </b> {{ getNome(form.responsavel, pessoas) }}
                    </li>
                    <li>
                        <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                        <b>Entrevistada: </b> {{ getNome(form.entrevistado, pessoas) }}
                    </li>
                    <li>
                        <svg class="inline mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>
                        <b>Status: </b> {{ form.status }}
                    </li>
                </ul>
            </div>
        </div>
        <!--mt-96 md:mt-28-->
        <nav aria-label="Progress" class="">
            <ol role="list" class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                <li v-for="(aba, index) in abas" :key="index" class="relative md:flex md:flex-1"  :class="{'bg-yellow-400': aba.visible, 'bg-blue-500': aba.semNumero}" 
                :style="{ 
                    'background-color': aba.visible ? $store.state.colorPrincipal : aba.semNumero ? $store.state.colorSecundaria : '', 
                    'color': aba.visible ? invertColor($store.state.colorPrincipal, true) : aba.semNumero ? invertColor($store.state.colorSecundaria, true) : '' 
                }
                ">
                    <a @click="setAba(index)" href="#" class="flex items-center px-6 py-4 text-sm font-medium " aria-current="step">
                        <span v-if="!aba.semNumero" class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-black-600">
                            <span v-show="!aba.visible" class="text-black-600">{{index + 1}}</span>
                            <svg v-show="aba.visible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                        </span>
                        <span class="ml-4 text-sm font-medium text-black-600" :class="{'text-white': aba.semNumero}">{{aba.nome}}</span>
                    </a>
                </li> 

            </ol>
        </nav>

        <div class="mx-1 md:mx-5 my-8">
            <div class="mt-5 md:mt-0">
                <div class="flex" v-if="inicialmenteDesativada">
                    <label for="naoContemAvaliacao" class="px-3 block text-sm font-medium my-3">
                        <input type="checkbox" v-model="form.ativo" class="rounded-sm" id="naoContemAvaliacao">
                        <span class="ml-2">
                            Ativada <span class="text-red-500">(marque essa opção e salve para reativar a atividade)</span>
                        </span>
                    </label>
                    <button
                        @click="excluirDefinitivamente"
                        type="button" 
                        class="text-white hover:bg-red-500 bg-red-700 text-sm rounded py-1 px-2 mx-1 my-1">
                        Excluir definitivamente
                    </button>
                </div>
                <form action="#" method="POST">
                    
                    <div v-show="abas[0].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-5">
                            <div class="col-span-12 lg:col-span-4 2xl:col-span-3" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="tipo_responsavelQMA" class="block text-sm font-medium">
                                    <b>1.1</b> 
                                    Perfil de quem está preenchendo
                                </label>
                                <select required v-model="form.tipo_responsavelQMA" :disabled="$store.state.user.cliente ? true : false" name="tipo_responsavelQMA" id="tipo_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="consultor">Próprio consultor (responsável do login administrador)</option>
                                    <option value="profissional">Outro profissional no projeto</option>
                                    <option value="pessoa">Uma pessoa na empresa</option>
                                </select>
                            </div>
                            <div v-if="form.tipo_responsavelQMA === 'consultor'" class="col-span-12 lg:col-span-4 2xl:col-span-3" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="responsavel" class="block text-sm font-medium">
                                    <b>1.2</b> 
                                    Responsável direto pelo preenchimento
                                </label>
                                <label class="block text-sm font-medium mt-5">
                                    {{ form.consultor_responsavelQMA ? form.consultor_responsavelQMA : 'Login Administrador' }}
                                </label>
                            </div>
                            <div v-if="form.tipo_responsavelQMA === 'pessoa'" class="col-span-12 lg:col-span-4 2xl:col-span-3" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="pessoa_responsavelQMA" class="block text-sm font-medium">
                                    <b>1.2</b> 
                                    Responsável direto pelo preenchimento
                                </label>
                                <select @change="changePessoaResponsavel()" :disabled="$store.state.user.cliente ? true : false" required v-model="form.pessoa_responsavelQMA" name="pessoa_responsavelQMA" id="pessoa_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                                </select>
                            </div>
                            <div v-if="form.tipo_responsavelQMA === 'profissional'" class="col-span-12 lg:col-span-4 2xl:col-span-3" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="profissional_responsavelQMA" class="block text-sm font-medium">
                                    <b>1.2</b> 
                                    Responsável direto pelo preenchimento
                                </label>
                                <select :disabled="$store.state.user.cliente ? true : false" required v-model="form.profissional_responsavelQMA" name="profissional_responsavelQMA" id="profissional_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-4 2xl:col-span-3" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="responsavel" class="block text-sm font-medium">
                                    <b>1.3</b> 
                                    Responsável pela atividade/processo

                                    <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('PessoaNaEmpresa')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600">
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <select :disabled="$store.state.user.cliente ? true : false" required v-model="form.responsavel" name="responsavel" id="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-4 2xl:col-span-3" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="entrevistado" class="block text-sm font-medium">
                                    <b>1.4</b> 
                                    Entrevistado(a)

                                </label>
                                <select :disabled="$store.state.user.cliente ? true : false" required v-model="form.entrevistado" name="entrevistado" id="entrevistado" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-6" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="name" class="block text-sm font-medium">
                                    <b>1.5</b> 
                                    Nome da atividade / processo

                                </label>
                                <textarea rows="3" :disabled="$store.state.user.cliente ? true : false" v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                </textarea>
                            </div>
                            <div class="col-span-12 lg:col-span-6">
                                <label for="descricao" class="block text-sm font-medium">
                                    <b>1.6</b> 
                                    Descrição da Atividade/Processo

                                </label>
                                <textarea v-model="form.descricao" type="text" rows="3" name="descricao" id="descricao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div>

                            <div class="col-span-12 lg:col-span-4">
                                <label for="setor" class="block text-sm font-medium">
                                    <b>1.7</b> 
                                    Setor que desenvolve a atividade

                                    <svg @click="novoItem('Setor')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <select required v-model="form.setor" name="setor" id="setor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                                </select>
                            </div>

                            <div class="col-span-12 lg:col-span-4" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="agenteTratamento" class="block text-sm font-medium">
                                    <b>1.8</b> 
                                    Empresa como agente de tratamento
                                    <svg v-tooltip="{ content: tooltip['1.8'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>
                                <select :disabled="$store.state.user.cliente ? true : false" required v-model="form.agente" name="agenteTratamento" id="agenteTratamento" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                </select> 
                            </div>

                            <div class="col-span-12 lg:col-span-4">
                                <label for="qtd" class="block text-sm font-medium">
                                    <b>1.9</b> 
                                    Volumetria (quantas vezes realiza e com qual frequência)
                                    <svg v-tooltip="{ content: tooltip['1.9'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                </label>
                                <div class="grid grid-cols-12 gap-2">
                                    <input placeholder="ex: 1" v-model="form.qtd" type="number" name="qtd" id="qtd" class="col-span-2 mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    <div class="col-span-12 lg:col-span-4">
                                        <select required v-model="form.frequencia" name="frequencia" id="frequencia" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                            <option value="diaria">Diária</option>
                                            <option value="semanal">Semanal</option>
                                            <option value="mensal">Mensal</option>
                                            <option value="anual">Anual</option>
                                            <option value="outro">Outra</option>
                                        </select>
                                    </div>
                                    <div v-if="form.frequencia === 'outro'" class="col-span-12 lg:col-span-6">
                                        <input placeholder="Ex: Toda semana somente em Julho" type="text" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" id="Físico" name="outraFrequencia" v-model="form.outraFrequencia">
                                    </div>
                                </div>

                            </div>

                            <div class="col-span-12 lg:col-span-4">
                                <span class="text-sm font-medium">
                                    <b>1.10</b> Existe decisão automatizada? Se "sim" explique sobre:
                                    <svg v-tooltip="{ content: tooltip['1.10'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </span>
                                <div class="grid grid-cols-12">
                                    <div class="col-span-12 lg:col-span-2">
                                        <select required v-model="form.decisaoAutomatizada" name="decisaoAutomatizada" id="decisaoAutomatizada" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                            <option :value="true">Sim</option>
                                            <option :value="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-span-12 lg:col-span-10 ml-2" :class="!form.decisaoAutomatizada ? 'opacity-50' : ''">
                                        <textarea placeholder="digite aqui a explicação" :disabled="!form.decisaoAutomatizada ? true : false" v-model="form.descricaoDecisaoAutomatizada" rows="3" type="text" name="descricaoDecisaoAutomatizada" id="descricaoDecisaoAutomatizada" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-12 lg:col-span-8" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <span class="text-sm font-medium">
                                    <b>1.11</b> Você acredita que esta atividade precisa ser subdividida em microprocessos/atividades ?
                                    <svg v-tooltip="{ content: tooltip['1.11'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                    
                                    <svg v-if="!$store.state.user.cliente" @click="form.microatividades.push({ ativa: false, descricao: '', setor: setores[0]._id})" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </span>
                                <div class="grid grid-cols-12 gap-2" v-for="(microatividade, index) in form.microatividades" :key="index">
                                    <div class="col-span-12 lg:col-span-2">
                                        <select v-model="microatividade.ativa" required name="microatividade_ativada" id="microatividade_ativada" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                            <option :value="true">Sim</option>
                                            <option :value="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-span-12 lg:col-span-4">
                                        <input :disabled="!microatividade.ativa" v-model="microatividade.descricao" placeholder="Nome da atividade / processo" type="text" name="descricao_atividade" id="descricao_atividade" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    </div>
                                    <div class="col-span-12 lg:col-span-3" >
                                        <select :disabled="!microatividade.ativa" required v-model="microatividade.setor" name="setor_atividade" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                            <option v-for="setor in setores" :value="setor._id" :key="setor._id">{{ setor.nome }}</option>
                                        </select>
                                        
                                    </div>
                                    <div class="col-span-12 lg:col-span-3 flex" v-show="!$store.state.user.cliente">
                                        <button :disabled="!microatividade.ativa || microatividade.atividadeGerada" :class="{'opacity-50': !microatividade.ativa || microatividade.atividadeGerada}" @click="gerarMicroatividade(index)" type="button" class="mr-1 text-white text-center w-full hover:bg-yellow-600 bg-yellow-500 rounded-full py-2 px-3 mt-1">
                                            Gerar
                                        </button>
                                        <button v-if="microatividade.atividadeGerada" type="button" @click="apagarMicroatividade(index)" class="text-white text-center hover:bg-red-600 bg-red-500 rounded-full py-2 px-3 mt-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,52H172V40a20,20,0,0,0-20-20H104A20,20,0,0,0,84,40V52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM92,40a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V52H92ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM108,104v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z"></path></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--infoPreliminares-->
                        <hr v-if="!$store.state.user.cliente" class="mt-5"/>
                        <div v-if="!$store.state.user.cliente" class="grid grid-cols-12 gap-2 mt-3 bg-slate-300 px-4 py-2">
                            <div class="col-span-12 lg:col-span-3">
                                <div class="flex flex-col gap-1 text-sm">
                                    <span class=" font-bold">
                                        Pontos de atenção
                                    </span>
                                    
                                    <div>
                                        <button 
                                            type="button" 
                                            @click="form.infoPreliminares.cenario.push({ ideal: '', real: ''})" 
                                            class="text-white hover:bg-green-600 bg-green-500 rounded-lg py-2 px-4 my-1">
                                            Adicionar Item
                                        </button>
                                    </div>

                                    <div>
                                        <span class="font-bold">
                                            Atenção
                                        </span>
                                        <span>
                                            em breve este campo será descontinuado e substituido pelo quadro abaixo
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-9">
                                <div v-for="(cenario, idx) in form.infoPreliminares.cenario" :key="idx" class="mb-2">
                                    <div class="grid grid-cols-12 gap-2 text-sm font-bold">
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="infoPreliminaresCenarioReal" class="block">Pontos de atenção</label>
                                            <textarea rows="6" v-model="cenario.real" type="text" name="infoPreliminaresCenarioReal" id="infoPreliminaresCenarioReal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="infoPreliminaresCenarioIdeal" class="block">Sugestão</label>
                                            <textarea rows="6" v-model="cenario.ideal" type="text" name="infoPreliminaresCenarioIdeal" id="infoPreliminaresCenarioIdeal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                                        </div>
                                        <div class="col-span-12 lg:col-span-2">
                                            <label for="infoPreliminaresCenarioReal" class="block">Aprovação</label>
                                            <textarea rows="6" v-model="cenario.aprovada" type="text" name="infoPreliminaresCenarioReal" id="infoPreliminaresCenarioReal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="infoPreliminaresCenarioIdeal" class="block">Prazo</label>
                                            <textarea rows="6" v-model="cenario.prazo" type="text" name="infoPreliminaresCenarioIdeal" id="infoPreliminaresCenarioIdeal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                                        </div>
                                        <div class="col-span-12 lg:col-span-1 text-center">
                                            <button type="button" @click="form.infoPreliminares.cenario.splice(idx, 1)" class="text-white text-center hover:bg-red-600 bg-red-500 rounded-full py-2 px-3 mt-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,52H172V40a20,20,0,0,0-20-20H104A20,20,0,0,0,84,40V52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM92,40a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V52H92ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM108,104v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>
                    <div v-show="abas[1].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-5">
                            <div class="col-span-12 lg:col-span-3">
                                <label for="tipotitular" class="block text-sm font-medium">
                                    <b>2.1</b> 
                                    Perfil/tipo do titular:
                                    <svg v-tooltip="{ content: tooltip['2.1'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                    
                                    <svg @click="novoItem('Tipo Titulares')" class="h-6 inline text-green-600" xmlns="http://www.w3.org/2000/svg"  fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                    <svg v-tooltip="{ content: tooltip['2.1-w'], html: true }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#f2be02" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM120,104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,192Z"></path></svg>
                                </label>
                                <multiselect  @input="onSelectTipoTitular" v-model="form.tipotitular" :options="tipotitulares" :multiple="true" :close-on-select="false" :taggable="true" label="tipo" trackBy="_id" id="tipotitular" placeholder="selecione os tipos de titulares" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <label class="block text-sm font-medium"> 
                                    <b>2.2</b> 
                                    Dados comuns (e críticos) tratados:
                                    <svg v-tooltip="{ content: tooltip['2.2'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                    <svg @click="novoItem('Dados')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect v-model="form.dados" :options="dados" :multiple="true" :close-on-select="false" @input="onSelect" @remove="onDadoRemove" :custom-label="customLabel" :taggable="true" label="nome" trackBy="_id" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <label class="block text-sm font-medium"> 
                                    <b>2.3</b> 
                                    Dados sensíveis tratados:
                                    <svg v-tooltip="{ content: tooltip['2.3'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                    <svg @click="novoItem('DadosSensiveis')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect v-model="form.dadossensiveis" :options="dadossensiveis" :multiple="true" :close-on-select="false" @input="onSelect" @remove="onDadoRemove" :custom-label="customLabel" :taggable="true" label="nome" trackBy="_id" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                            </div>

                            <div class="col-span-12 lg:col-span-3">
                                <label class="block text-sm font-medium"> 
                                    <b>2.4</b> 
                                    Categoria / Tipo (s) dos dados tratados:
                                    <svg v-tooltip="{ content: tooltip['2.4'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                </label>
                                <multiselect v-model="form.categoriasdados" :options="categoriasdados" :multiple="true" :close-on-select="false" @input="onSelect" :custom-label="customLabel" :taggable="true" label="nome" trackBy="_id" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                            </div>

                            <div class="col-span-12 lg:col-span-3">
                                <label for="finalidade" class="block text-sm font-medium">
                                    <b>2.5</b> 
                                    Finalidade/motivo da atividade
                                    <svg v-tooltip="{ content: tooltip['2.5'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                    
                                    <svg @click="novoItem('Finalidade')" xmlns="http://www.w3.org/2000/svg" class="h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>

                                </label>
                                
                                <multiselect v-model="form.finalidade" :options="finalidades" :multiple="true" :close-on-select="false" @input="onSelectFinalidade" :taggable="true" label="titulo" trackBy="_id" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                                <small> {{ mostraTextoFinalidade() }} </small>
                            </div>
                            <div class="col-span-12 lg:col-span-6" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="baselegal" class="block text-sm font-medium">
                                    <b>2.6</b> Hipótese de tratamento / base legal
                                    <svg v-tooltip="{ content: tooltip['2.6'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                    <svg @click="novoItem('BaseLegal')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                            <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                    <svg v-tooltip="{ content: tooltip['2.6-w'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#f2be02" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM120,104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,192Z"></path></svg>
                                </label>

                                <multiselect :disabled="$store.state.user.cliente ? true : false" v-model="form.baselegal" :options="baselegais" :multiple="true" :close-on-select="false" @input="onSelect" :taggable="true" label="nome" trackBy="_id" id="dado" placeholder="Selecione as bases" selectedLabel="" deselectLabel="" selectLabel="" class="mt-2"/>
                                <div class="col-span-12 2xl:col-span-4 flex gap-2 text-xs py-2">
                                    <input 
                                    type="checkbox" 
                                    v-model="form.considerarLegitimoInteresse"
                                    class="rounded-sm" 
                                    id="considerarLegitimoInteresse"
                                    />
                                    <label for="considerarLegitimoInteresse">Considerar para teste de legitimo interesse</label>
                                </div> 
                                <textarea placeholder="Observações do item 2.6" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" name="observacaohipotese" id="observacaohipotese" rows="1"></textarea>
                            </div>

                            <div class="col-span-12 lg:col-span-3" :class="baselegais.length && !form.baselegal.length || $store.state.user.cliente ? 'opacity-50' : ''">
                                <label for="legislacoes" class="block text-sm font-medium"> 
                                    <b>2.7</b> 
                                    Legislações Aplicada
                                    <svg v-tooltip="{ content: tooltip['2.7'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                    
                                    <svg @click="novoItem('Legislacoes')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect :disabled="baselegais.length && !form.baselegal.length || $store.state.user.cliente ? true : false" v-model="form.legislacoes" :options="legislacoes" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="legislacoes" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                           
                            <div class="col-span-12 lg:col-span-6">
                                <label for="tipotratamentodados" class="block text-sm font-medium"> 
                                    <b>2.8</b> 
                                    Tipo de tratamento dos dados na atividade
                                    <svg v-tooltip="{ content: tooltip['2.8'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                    <a target="_bank" href="/tipoTratamento.pdf" class="inline text-white text-xs_3 hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-2 mx-2">
                                        + Detalhes
                                    </a>
                                </label>
                                <multiselect v-model="form.tipotratamentodados" @input="updateTratamentodados(form.tipotratamentodados)" :options="tratamentodados" :custom-label="customLabelTratamentos" :multiple="true" :close-on-select="false" :taggable="true" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />                 
                            </div>
                            <div class="col-span-12 lg:col-span-6">
                                <label for="faseDados" class="block text-sm font-medium"> 
                                    <b>2.9</b> 
                                    Fases no ciclo de vida
                                    <svg v-tooltip="{ content: tooltip['2.9'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                    <button type="button" @click="$modal.show('modalFaseCiclo')" class="inline text-white text-xs_3 hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-2 mx-2">
                                        + Detalhes
                                    </button>
                                </label>
                                <multiselect :disabled="true" v-model="form.fasesdados" :options="fasesdados" :multiple="true" :close-on-select="false" :taggable="true" id="fases" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />                 
                            </div>
                            <div class="col-span-12 lg:col-span-2">
                                <label class="block text-sm font-medium"> 
                                    <b>2.10</b> 
                                    Formato do Dado Pessoal
                                    <svg v-tooltip="{ content: tooltip['2.10'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>

                                <select v-model="form.formatodadopessoal" name="formatodadopessoal" id="formatodadopessoal" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="Físico">Físico</option>
                                    <option value="Digital">Digital</option>
                                    <option value="Ambos">Ambos</option>
                                </select>
                            </div> 
                            <div class="col-span-12 lg:col-span-6">
                                <label for="quantidadeTitulares" class="block text-sm font-medium"> 
                                    <b>2.11</b> 
                                    Quantidade de titulares envolvidos
                                    <svg v-tooltip="{ content: tooltip['2.11'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>
                                
                                <div class="grid grid-cols-2 grid-flow-row-dense" v-for="tipo in form.quantidadeTitulares" :key="tipo.tipo.nome"  >
                                    <label for="" class="mt-4 mr-2 flex-1">{{ tipo.tipo.tipo }}</label>
                                    <input 
                                        id="quantidadeTitulares" 
                                        name="quantidadeTitulares" 
                                        v-model="tipo.qtd" 
                                        type="number" 
                                        class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" 
                                    >
                                </div>
                            </div> 
                            <div class="col-span-12 lg:col-span-4">
                                <label for="abrangenciaGeografica" class="block text-sm font-medium"> 
                                    <b>2.12</b> 
                                    Abrangência geográfica
                                    <svg v-tooltip="{ content: tooltip['2.12'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>
                                <input id="abrangenciaGeografica" name="abrangenciaGeografica" v-model="form.abrangenciaGeografica" type="text" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" >
                            </div> 
                            <div class="col-span-12 lg:col-span-3">
                                <label for="canais" class="block text-sm font-medium">
                                    <b>2.13</b> 
                                    Canais de tratamento
                                    <svg v-tooltip="{ content: tooltip['2.13'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                   
                                    <svg @click="novoItem('Canais')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect v-model="form.canais" :options="canais" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="canais" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                            <div class="col-span-12 lg:col-span-3" :class="form.tipotratamentodados && form.tipotratamentodados.indexOf('armazenamento') === -1 && form.tipotratamentodados.indexOf('arquivamento') === -1 ? 'opacity-50': ''">
                                <label for="armazenamento" class="block text-sm font-medium">
                                    <b>2.14</b> 
                                    Local de armazenamento e/ou arquivamento
                                    <svg v-tooltip="{ content: tooltip['2.14'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                    
                                    <svg @click="novoItem('Armazenamento')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect :disabled="form.tipotratamentodados && form.tipotratamentodados.indexOf('armazenamento') === -1 && form.tipotratamentodados.indexOf('arquivamento') === -1 ? true : false" v-model="form.armazenamento" :options="armazenamentos" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="armazenamento" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <label for="prazoRetencao" class="block text-sm font-medium">
                                    <b>2.15</b> 
                                    Prazo de retenção / dado pessoal (doc específico)
                                    <svg v-tooltip="{ content: tooltip['2.15'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>


                                <textarea rows="4" name="prazoRetencao" id="prazoRetencao" v-model="form.tipoPrazoRetencao" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"></textarea>
                                <!--<select required v-model="form.tipoPrazoRetencao" name="status" id="status" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="Dias">Dias</option>
                                    <option value="Semanas">Semanas</option>
                                    <option value="Meses">Meses</option>
                                    <option value="Anos">Anos</option>
                                    <option value="Indeterminado">Indeterminado</option>
                                </select>

                                <label v-if="form.tipoPrazoRetencao !== 'Indeterminado'" for="prazoRetencao" class="block text-sm font-medium mt-2">
                                    Prazo de Retenção <span v-if="form.tipoPrazoRetencao">({{ form.tipoPrazoRetencao }})</span>
                                </label>
                                <input v-if="form.tipoPrazoRetencao !== 'Indeterminado'" v-model="form.prazoRetencao" type="number" name="prazoRetencao" id="prazoRetencao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>-->
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <label for="formaDescarte" class="block text-sm font-medium">
                                    <b>2.16</b> 
                                    Forma de descarte de dado
                                    <svg v-tooltip="{ content: tooltip['2.16'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                </label>
                                <textarea rows="1" v-model="form.formaDescarte" type="text" name="formaDescarte" id="formaDescarte" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>

                            <div class="col-span-12 lg:col-span-3">
                                <label for="titularmenor" class="block text-sm font-medium">
                                    <b>2.17</b> 
                                    Titular pode ser menor
                                    <svg v-tooltip="{ content: tooltip['2.17'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                </label>
                                <select required v-model="form.titularmenor" name="titularmenor" id="titularmenor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>

                            <div class="col-span-12 lg:col-span-3" :class="!form.titularmenor ? 'opacity-50' : ''">
                                <label for="consetimentopais" class="block text-sm font-medium">
                                    <b>2.18</b> 
                                    Existe consentimento
                                    <svg v-tooltip="{ content: tooltip['2.18'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                </label>
                                <select :disabled="!form.titularmenor" required v-model="form.consetimentopais" name="titularmenor" id="titularmenor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>

                            <div class="col-span-12 lg:col-span-3">
                                <label for="titularidoso" class="block text-sm font-medium">
                                    <b>2.19</b> 
                                    Titular pode ser idoso
                                    <svg v-tooltip="{ content: tooltip['2.19'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                </label>
                                <select required v-model="form.titularidoso" name="titularidoso" id="titularidoso" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <label for="titularvulneravel" class="block text-sm font-medium">
                                    <b>2.20</b> 
                                    Titular pode ser vulnerável
                                    <svg v-tooltip="{ content: tooltip['2.20'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                </label>
                                <select required v-model="form.titularvulneravel" name="titularvulneravel" id="titularvulneravel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>
                        </div>

                         <!--dadosColetados-->
                        <hr v-if="!$store.state.user.cliente" class="mt-5"/>
                        <div v-if="!$store.state.user.cliente" class="grid grid-cols-12 gap-2 mt-3 bg-slate-300 px-4 py-2">
                            <div class="col-span-12 lg:col-span-3">
                                <div class="flex flex-col gap-1 text-sm">
                                    <span class=" font-bold">
                                        Pontos de atenção
                                    </span>
                                    
                                    <div>
                                        <button 
                                            type="button" 
                                            @click="form.dadosColetados.cenario.push({ ideal: '', real: ''})"
                                            class="text-white hover:bg-green-600 bg-green-500 rounded-lg py-2 px-4 my-1">
                                            Adicionar Item
                                        </button>
                                    </div>

                                    <div>
                                        <span class="font-bold">
                                            Atenção
                                        </span>
                                        <span>
                                            em breve este campo será descontinuado e substituido pelo quadro abaixo
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-9">
                                <div v-for="(cenario, idx) in form.dadosColetados.cenario" :key="idx" class="mb-2">
                                    <div class="grid grid-cols-12 gap-2 text-sm font-bold">
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="dadosColetadosCenarioReal" class="block">Pontos de atenção</label>
                                            <textarea rows="6" v-model="cenario.real" type="text" name="dadosColetadosCenarioReal" id="dadosColetadosCenarioReal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="dadosColetadosCenarioIdeal" class="block">Sugestão</label>
                                            <textarea rows="6" v-model="cenario.ideal" type="text" name="dadosColetadosCenarioIdeal" id="dadosColetadosCenarioIdeal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-2">
                                            <label for="sugestaoAprovada" class="block">Aprovação</label>
                                            <textarea rows="6" v-model="cenario.aprovada" type="text" name="sugestaoAprovada" id="sugestaoAprovada" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="prazoImplementar" class="block">Prazo</label>
                                            <textarea rows="6" v-model="cenario.prazo" type="text" name="prazoImplementar" id="prazoImplementar" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-1 text-center">
                                            <button type="button" @click="form.dadosColetados.cenario.splice(idx, 1)" class="text-white text-center hover:bg-red-600 bg-red-500 rounded-full py-2 px-3 mt-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,52H172V40a20,20,0,0,0-20-20H104A20,20,0,0,0,84,40V52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM92,40a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V52H92ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM108,104v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>

                    <div v-show="abas[2].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-3 mt-3">
                            <div class="col-span-12">
                                <span class="text-sm font-medium"><b>3.1 </b>Alguma outra pessoa trata os dados no setor / departamento ? Caso “sim”, indique e registre observações que considera relevante:</span>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <select @change="setValidacoes" required v-model="form.origemAcesso" name="origemAcesso" id="origemAcesso" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-4 flex" :class="!form.origemAcesso ? 'opacity-50' : ''">
                                <multiselect :disabled="!form.origemAcesso ? true : false" v-model="form.origemAcessoPessoas" :options="pessoas" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="origemAcessoPessoas" placeholder="quem" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                                <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('PessoaNaEmpresa')" xmlns="http://www.w3.org/2000/svg" class="mx-1 mt-4 h-6 inline text-green-600">
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                </svg>
                            </div>
                            <div class="col-span-12 lg:col-span-5" :class="!form.origemAcesso ? 'opacity-50' : ''">
                                <input placeholder="como" v-model="form.comoOrigemAcessoPessoas" type="text" name="comoOrigemAcessoPessoas" id="comoOrigemAcessoPessoas" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>

                            <div class="col-span-12">
                                <span class="text-sm font-medium"><b>3.2 </b>Os dados são compartilhados com outro(s) setores? Caso “sim”, indique e registre observações que considera relevante</span>
                                
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <select @change="setValidacoes" required v-model="form.compartilhadaSetor" name="compartilhadaSetor" id="compartilhadaSetor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-4 flex" :class="!form.compartilhadaSetor ? 'opacity-50' : ''">
                                <multiselect :disabled="!form.compartilhadaSetor ? true : false" v-model="form.setoresCompartilhado" :options="setores" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="origemAcessoPessoas" placeholder="quais" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                                <svg @click="novoItem('Setor')" xmlns="http://www.w3.org/2000/svg" class="mt-4 mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                </svg>
                            </div>
                            <div class="col-span-12 lg:col-span-5" :class="!form.compartilhadaSetor ? 'opacity-50' : ''">
                                <input placeholder="como" v-model="form.comoCompartilhadaSetor" type="text" name="comoCompartilhadaSetor" id="comoCompartilhadaSetor" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>

                            <div class="col-span-12">
                                <span class="text-sm font-medium"><b>3.3 </b>Os dados são compartilhados / transferidos para organizações ou empresas do mesmo grupo econômico?</span>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <select @change="setValidacoes" required v-model="form.compartilhadaGrupoEconomico" name="compartilhadaGrupoEconomico" id="compartilhadaGrupoEconomico" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <div class="flex" v-for="(empresa, index) in form.grupoEconomicoCompartilhado" :key="index">
                                    <select v-model="empresa.empresa" @change="changeAgenteEmpresa(empresa)" name="responsavel" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
                                    </select>
                                   
                                    <svg @click="novoItem('compartilhadaGrupoEconomico', index)" xmlns="http://www.w3.org/2000/svg" class="mx-1 mt-4 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <div v-for="(empresa, index) in form.grupoEconomicoCompartilhado" :key="index">
                                    <select required v-model="empresa.agente" name="grupoAgenteTratamento" id="grupoAgenteTratamento" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                    </select> 
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-2">
                                <div v-for="(empresa, index) in form.grupoEconomicoCompartilhado" :key="index">
                                    <input placeholder="Como são compartilhado" type="text" v-model="empresa.como" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-1">
                                <div v-for="(empresa, index) in form.grupoEconomicoCompartilhado" :key="index" class="flex">
                                    <button type="button" @click="form.grupoEconomicoCompartilhado.push({ empresa:'', como: ''})" v-if="form.compartilhadaGrupoEconomico" class="py-2 px-3 text-white hover:bg-green-600 bg-green-500 rounded-lg mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    <button type="button" @click="form.grupoEconomicoCompartilhado.splice(index, 1)" class="text-white hover:bg-red-500 bg-red-400 rounded-lg py-2 px-3 flex mt-1 ml-1">
                                        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="col-span-12">
                                <span class="text-sm font-medium"><b>3.4 </b>Os dados são compartilhados / transferidos para organizações ou empresas no Brasil?</span>
                            </div>

                            <div class="col-span-12 lg:col-span-3">
                                <select @change="setValidacoes" required v-model="form.compartilhadaEmpresa" name="compartilhadaEmpresa" id="compartilhadaEmpresa" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <div class="flex" v-for="(empresa, index) in form.empresasCompartilhado" :key="index">
                                    <select v-model="empresa.empresa" @change="changeAgenteEmpresa(empresa)" name="responsavel" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
                                    </select>
                                    
                                    <svg @click="novoItem('compartilhadaEmpresa', index)" xmlns="http://www.w3.org/2000/svg" class="mx-1 mt-4 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <div v-for="(empresa, index) in form.empresasCompartilhado" :key="index">
                                    <select required v-model="empresa.agente" name="empresasAgenteTratamento" id="empresasAgenteTratamento" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                    </select> 
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-2">
                                <div v-for="(empresa, index) in form.empresasCompartilhado" :key="index">
                                    <input placeholder="Como são compartilhados" type="text" v-model="empresa.como" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-1">
                                <div v-for="(empresa, index) in form.empresasCompartilhado" :key="index" class="flex">
                                    <button type="button" @click="form.empresasCompartilhado.push({ empresa:'', como: ''})" v-if="form.compartilhadaEmpresa" class="py-2 px-3 text-white hover:bg-green-600 bg-green-500 rounded-lg mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    <button type="button" @click="form.empresasCompartilhado.splice(index, 1)" class="text-white hover:bg-red-500 bg-red-400 rounded-lg py-2 px-3 flex mt-1 ml-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="col-span-12">
                                <span class="text-sm font-medium"><b>3.5</b> As informações são compartilhadas ou transferidas para outros países? Se for o caso, especifique o que é solicitado e, nas observações, registre informações relevantes</span>
                            </div>

                            <div class="col-span-12 lg:col-span-3">
                                <select @change="setValidacoes" required v-model="form.compartilhadaEmpresaEstrangeira" name="compartilhadaEmpresaEstrangeira" id="compartilhadaEmpresaEstrangeira" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                </select>
                            </div>
                            
                            <div class="col-span-12 lg:col-span-3">
                                <div v-for="(empresa, index) in form.empresasEstrangeiraCompartilhada" :key="index">
                                    <select required v-model="empresa.agente" name="estrangeiraAgenteTratamento" id="estrangeiraAgenteTratamento" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                    </select> 
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <div class="grid grid-cols-12 gap-2" v-for="(empresa, index) in form.empresasEstrangeiraCompartilhada" :key="index">
                                    <div class="col-span-12 lg:col-span-7">
                                        <input placeholder="Empresa / Organização" type="text" v-model="empresa.empresa" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    </div>
                                    <div class="col-span-12 lg:col-span-5">
                                        <input placeholder="País" type="text" v-model="empresa.pais"  class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">     
                                    </div>
                                </div>                   
                            </div>
                            <div class="col-span-12 lg:col-span-2">
                                <div v-for="(empresa, index) in form.empresasEstrangeiraCompartilhada" :key="index">
                                    <input placeholder="Como são compartilhados" type="text" v-model="empresa.como" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-1">
                                <div class="flex" v-for="(empresa, index) in form.empresasEstrangeiraCompartilhada" :key="index">
                                    <button type="button" @click="form.empresasEstrangeiraCompartilhada.push({ empresa:'', pais: '', como: ''})" v-if="form.compartilhadaEmpresaEstrangeira" class="py-2 px-3 text-white hover:bg-green-600 bg-green-500 rounded-lg mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    <button type="button" @click="form.empresasEstrangeiraCompartilhada.splice(index, 1)" class="text-white hover:bg-red-500 bg-red-400 rounded-lg py-2 px-3 flex mt-1 ml-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                         <!--acessoDados-->
                        <hr v-if="!$store.state.user.cliente" class="mt-5"/>
                        <div v-if="!$store.state.user.cliente" class="grid grid-cols-12 gap-2 mt-3 bg-slate-300 px-4 py-2">
                            <div class="col-span-12 lg:col-span-3">
                                <div class="flex flex-col gap-1 text-sm">
                                    <span class=" font-bold">
                                        Pontos de atenção
                                    </span>
                                    
                                    <div>
                                        <button 
                                            type="button" 
                                            @click="form.acessoDados.cenario.push({ ideal: '', real: ''})"
                                            class="text-white hover:bg-green-600 bg-green-500 rounded-lg py-2 px-4 my-1">
                                            Adicionar Item
                                        </button>
                                    </div>

                                    <div>
                                        <span class="font-bold">
                                            Atenção
                                        </span>
                                        <span>
                                            em breve este campo será descontinuado e substituido pelo quadro abaixo
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-9">
                                <div v-for="(cenario, idx) in form.acessoDados.cenario" :key="idx" class="mb-2">
                                    <div class="grid grid-cols-12 gap-2 text-sm font-bold">
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="acessoDadosCenarioReal" class="block">Pontos de atenção</label>
                                            <textarea rows="6" v-model="cenario.real" type="text" name="acessoDadosCenarioReal" id="acessoDadosCenarioReal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="acessoDadosCenarioIdeal" class="block">Sugestão</label>
                                            <textarea rows="6" v-model="cenario.ideal" type="text" name="acessoDadosCenarioIdeal" id="acessoDadosCenarioIdeal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-2">
                                            <label for="acessoDadosCenarioReal" class="block">Aprovação</label>
                                            <textarea rows="6" v-model="cenario.aprovada" type="text" name="acessoDadosCenarioReal" id="acessoDadosCenarioReal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="acessoDadosCenarioIdeal" class="block">Prazo</label>
                                            <textarea rows="6" v-model="cenario.prazo" type="text" name="acessoDadosCenarioIdeal" id="acessoDadosCenarioIdeal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-1 text-center">
                                            <button type="button" @click="form.acessoDados.cenario.splice(idx, 1)" class="text-white text-center hover:bg-red-600 bg-red-500 rounded-full py-2 px-3 mt-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,52H172V40a20,20,0,0,0-20-20H104A20,20,0,0,0,84,40V52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM92,40a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V52H92ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM108,104v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>

                    </div>

                    <div v-show="abas[3].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-12 mt-3">
                            <div class="col-span-12 lg:col-span-12 mb-4">
                                <label for="medidasSeguranca" class="block text-sm font-medium">
                                    <b>4.1</b>
                                    Informe quais medidas organizacionais/administrativos de segurança existem na atividade
                                    <svg v-tooltip="{ content: tooltip['4.1'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                    <svg @click="novoItem('MedidasSeguranca')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>

                                    <a target="_bank" href="/ORG.pdf" class="inline text-white text-xs_3 hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-2 mx-2">
                                        + Detalhes
                                    </a>
                                </label>
                                <multiselect v-model="form.medidasSeguranca" :options="medidasSeguranca" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="medidasSeguranca" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>

                            <div class="col-span-12 lg:col-span-12">
                                <label for="medidasSegurancaTecnica" class="block text-sm font-medium">
                                    <b>4.2</b> 
                                    Informe quais medidas técnicas de segurança existem na atividade
                                    <svg v-tooltip="{ content: tooltip['4.2'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                    <svg @click="novoItem('MedidasSegurancaTecnica')" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600" fill="#059669" viewBox="0 0 256 256" >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>

                                    <a target="_bank" href="/TEC.pdf" class="inline text-white text-xs_3 hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-2 mx-2">
                                        + Detalhes
                                    </a>
                                </label>
                                <multiselect v-model="form.medidasSegurancaTecnica" :options="medidasSegurancaTecnica" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="medidasSegurancaTecnica" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>

                            <div class="col-span-12 lg:col-span-12 mt-4">
                                <label for="principios" class="block text-sm font-medium">
                                    <b>4.3</b> 
                                    Você entende que os princípios da LGPD estão sendo atendidos nesta atividade, se sim indique quais e registre suas percepções sobre este detalhe
                                    <svg v-tooltip="{ content: tooltip['4.3'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>
                                <div class="grid grid-cols-12 mt-3 gap-2" v-for="(principio, index) in form.principiosAtendidos" :key="index" :class="$store.state.user.cliente ? 'opacity-50' : ''">

                                    <div class="col-span-12 lg:col-span-2">
                                        <div class="text-sm mt-2">
                                            <span  v-if="'I' === principio.principio">I - finalidade</span>
                                            <span  v-if="'II' === principio.principio">II - adequação</span>
                                            <span  v-if="'III' === principio.principio">III - necessidade</span>
                                            <span  v-if="'IV' === principio.principio">IV - livre acesso</span>
                                            <span  v-if="'V' === principio.principio">V - qualidade dos dados</span>
                                            <span  v-if="'VI' === principio.principio">VI - transparência</span>
                                            <span  v-if="'VII' === principio.principio">VII - segurança</span>
                                            <span  v-if="'VIII' === principio.principio">VIII - prevenção</span>
                                            <span  v-if="'IX' === principio.principio">IX - não discriminação</span>
                                            <span  v-if="'X' === principio.principio">X - responsabilização e prestação de contas</span>
                                        </div>
                                    </div>

                                    <div class="col-span-12 lg:col-span-2" >
                                        <div class="mt-2">
                                            <label :for="principio.principio" class="inline-flex items-center">
                                                <input :disabled="$store.state.user.cliente ? true : false" type="radio" class="form-radio" :id="principio.principio" v-model="principio.entende" :value="true">
                                                <span class="ml-2">Sim</span>
                                            </label>
                                            <label :for="principio.principio" class="inline-flex items-center ml-6">
                                                <input :disabled="$store.state.user.cliente ? true : false" type="radio" class="form-radio" :id="principio.principio" v-model="principio.entende" :value="false">
                                                <span class="ml-2">Não</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-span-12 lg:col-span-8">
                                        <textarea placeholder="Escreva suas percepções aqui" v-model="principio.obs" rows="1" name="principio_obs" id="principio_obs" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-12 mt-16">
                                <label for="obsConformidades" class="block text-sm font-medium">
                                    <b>4.4</b> 
                                    Registre abaixo o que entender que faça sentido consignar nesta atividade / processo
                                </label>
                                <textarea rows="6" v-model="form.obsConformidades" type="text" name="obsConformidades" id="obsConformidades" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>

                            <div class="col-span-12 mt-16" :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <label class="block text-sm font-medium">
                                    <b>4.5</b> 
                                    Avaliação preliminar de risco
                                    <svg v-tooltip="{ content: tooltip['4.5'], html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>
                               <div class="grid grid-cols-12 mt-3 gap-2">
                                   <div class="col-span-12 lg:col-span-4">
                                       Ativo em risco na atividade
                                       <div class="flex">
                                           <multiselect :disabled="$store.state.user.cliente ? true : false" v-model="form.avaliacao_preliminar.ativos" :options="ativos" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="ativos" placeholder="selecione os ativos" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                                           <svg v-show="!$store.state.user.cliente" fill="#059669" viewBox="0 0 256 256" @click="novoItem('Ativo')" xmlns="http://www.w3.org/2000/svg" class="mx-1 mt-4 h-6 inline text-green-600">
                                               <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                           </svg>
                                       </div>
                                   </div>
                                   <div class="col-span-12 lg:col-span-4">
                                       Vulnerabilidades identificadas
                                       <svg v-tooltip="{ content: 'Ao excluir uma vulnerabilidade avalie e remova manualmente as ameaças que julgar resolvidas.', html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                       <div class="flex">
                                           <multiselect :disabled="$store.state.user.cliente ? true : false" @input="onSelectVulnerabilidade"  v-model="form.avaliacao_preliminar.vulnerabilidades" :options="vulnerabilidades" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="ativos" placeholder="selecione as ameaças" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                                           <svg v-show="!$store.state.user.cliente" fill="#059669" viewBox="0 0 256 256" @click="novoItem('Vulnerabilidade')" xmlns="http://www.w3.org/2000/svg" class="mx-1 mt-4 h-6 inline text-green-600">
                                               <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                           </svg>
                                       </div>
                                   </div>
                                   <div class="col-span-12 lg:col-span-4">
                                       Ameaças na atividade
                                       <div class="flex">
                                           <multiselect :disabled="$store.state.user.cliente ? true : false" v-model="form.avaliacao_preliminar.ameacas" :options="ameacas" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="ativos" placeholder="selecione as ameaças" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"  />
                                           <svg v-show="!$store.state.user.cliente" fill="#059669" viewBox="0 0 256 256" @click="novoItem('Ameaca')" xmlns="http://www.w3.org/2000/svg" class="mx-1 mt-4 h-6 inline text-green-600">
                                               <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                           </svg>
                                       </div>
                                   </div>
                                   <div class="col-span-12 lg:col-span-4">
                                       Probabilidade de acontecer
                                       <select :disabled="$store.state.user.cliente ? true : false" @change="statusGrauDeRisco" v-model="form.avaliacao_preliminar.probabilidade" name="probabilidade" id="probabilidade" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                           <option value="muitobaixa">Muito Baixa</option>
                                           <option value="baixo">Baixo</option>
                                           <option value="medio">Médio</option>
                                           <option value="alto">Alto</option>
                                           <option value="muitoalto">Muito Alto</option>
                                       </select>
                                   </div>
                                   <div class="col-span-12 lg:col-span-4">
                                       Impacto caso aconteça  
                                       <select :disabled="$store.state.user.cliente ? true : false" @change="statusGrauDeRisco" v-model="form.avaliacao_preliminar.impacto" name="impacto" id="impacto" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                           <option value="muitobaixa">Muito Baixo</option>
                                           <option value="baixo">Baixo</option>
                                           <option value="medio">Médio</option>
                                           <option value="alto">Alto</option>
                                           <option value="muitoalto">Muito Alto</option>
                                       </select>
                                   </div>
                                   <div class="col-span-12 lg:col-span-4">
                                       Grau de risco preliminar 
                                       <svg v-tooltip="{ content: 'Atribuida automaticamente ao selecionar Probabilidade e Impacto conforme Matriz de risco 5x5', html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>

                                       <select :disabled="true" v-model="form.avaliacao_preliminar.grauderisco" name="grauderisco" id="grauderisco" class="opacity-30 mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                           <option value="muitobaixa">Muito Baixo</option>
                                           <option value="baixo">Baixo</option>
                                           <option value="medio">Médio</option>
                                           <option value="alto">Alto</option>
                                           <option value="muitoalto">Muito Alto</option>
                                       </select>
                                   </div>

                                    <div class="col-span-12">
                                        Observações sobre o grau de risco preliminar compreendido:
                                        <textarea :disabled="$store.state.user.cliente ? true : false" rows="2" v-model="form.avaliacao_preliminar.obs" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                        </textarea>
                                    </div>
                               </div>
                            </div>


                            <!-- <div class="col-span-12 mt-16">
                                <label for="anexosEvidencias" class="block text-sm font-medium">
                                    <b>4.6</b> 
                                    Evidências, anexe tudo que faça sentido para comprovar os dados informados desta atividade / processo
                                </label>
                                <vue-dropzone ref="anexosEvidencias" id="anexosEvidencias" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateAnexosEvidencias"></vue-dropzone>
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Arquivo
                                        </th>
                                        <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Opção
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="(item, idx) in this.form.anexosEvidencias" :key="item._id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                            <div class="text-sm text-gray-500">
                                                {{item.originalname ? item.originalname : ''}}
                                            </div>
                                            </div>
                                        </td>
                                        <td  class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                            <div class="text-sm text-blue-500">
                                                <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar</a>
                                                <button
                                                type="button"
                                                @click="this.form.evidencias.splice(idx, 1)" 
                                                class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                Remover
                                                </button>
                                            </div>
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                        </div>
                         <!--gestaoDeRisco-->
                        <hr v-if="!$store.state.user.cliente" class="mt-5"/>
                        <div v-if="!$store.state.user.cliente" class="grid grid-cols-12 gap-2 mt-3 bg-slate-300 px-4 py-2">
                            <div class="col-span-12 lg:col-span-3">
                                <div class="flex flex-col gap-1 text-sm">
                                    <span class=" font-bold">
                                        Pontos de atenção
                                    </span>
                                    
                                    <div>
                                        <button 
                                            type="button" 
                                            @click="form.gestaoDeRisco.cenario.push({ ideal: '', real: ''})"
                                            class="text-white hover:bg-green-600 bg-green-500 rounded-lg py-2 px-4 my-1">
                                            Adicionar Item
                                        </button>
                                    </div>

                                    <div>
                                        <span class="font-bold">
                                            Atenção
                                        </span>
                                        <span>
                                            em breve este campo será descontinuado e substituido pelo quadro abaixo
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-9">
                                <div v-for="(cenario, idx) in form.gestaoDeRisco.cenario" :key="idx" class="mb-2">
                                    <div class="grid grid-cols-12 gap-2 text-sm font-bold">
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="gestaoDeRiscoCenarioReal" class="block">Pontos de atenção</label>
                                            <textarea rows="6" v-model="cenario.real" type="text" name="gestaoDeRiscoCenarioReal" id="gestaoDeRiscoCenarioReal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="gestaoDeRiscoCenarioIdeal" class="block">Sugestão</label>
                                            <textarea rows="6" v-model="cenario.ideal" type="text" name="gestaoDeRiscoCenarioIdeal" id="gestaoDeRiscoCenarioIdeal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-2">
                                            <label for="gestaoDeRiscoCenarioReal" class="block">Aprovação</label>
                                            <textarea rows="6" v-model="cenario.aprovada" type="text" name="gestaoDeRiscoCenarioReal" id="gestaoDeRiscoCenarioReal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-3">
                                            <label for="gestaoDeRiscoCenarioIdeal" class="block">Prazo</label>
                                            <textarea rows="6" v-model="cenario.prazo" type="text" name="gestaoDeRiscoCenarioIdeal" id="gestaoDeRiscoCenarioIdeal" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                        </div>
                                        <div class="col-span-12 lg:col-span-1 text-center">
                                            <button type="button" @click="form.gestaoDeRisco.cenario.splice(idx, 1)" class="text-white text-center hover:bg-red-600 bg-red-500 rounded-full py-2 px-3 mt-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,52H172V40a20,20,0,0,0-20-20H104A20,20,0,0,0,84,40V52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM92,40a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V52H92ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM108,104v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>

                    <div v-show="abas[4].visible" class="px-5 pb-4">
                        
                        <div class="grid grid-cols-12 mt-3">
                            <div class="col-span-12">
                                5.1 - Registre aqui as orientações sobre correções, atualizações ou quaisquer observações que deseje transmitir ao responsável pela realização desta atividade
                            </div>
                            <div class="col-span-12">
                                <button type="button" @click="form.orientacoes.push({ data: moment().toDate(), texto: '', quem: $store.state.user.name})" class="py-2 px-3 text-white hover:bg-green-600 bg-green-500 rounded-lg mt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="grid grid-cols-12" v-for="(orientacao, index) in form.orientacoes" :key="index">
                            <div class="col-span-12">
                                <textarea :class="{'bg-gray-200': orientacao._id}" :disabled="orientacao._id" v-model="orientacao.texto" name="orientacao" id="orientacao" rows="5" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-xl"></textarea>
                            </div>
                            <div class="col-span-12 flex">
                                Data envio: {{ moment(orientacao.data).format("DD/MM/YYYY HH:mm:ss")  }} por {{ orientacao.quem }}
                                <svg @click="form.orientacoes.splice(index, 1)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-4 w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div class="px-5 pb-4 flex flex-col gap-4" v-if="form.pai.length>0">
                        <section v-for="(p, idx) in form.pai" :key="idx" class="bg-slate-200 text-sm">
                            <div class="flex bg-slate-300 p-4 justify-between" >
                                <div class="flex">
                                    <svg class="mr-2 fill-black" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg>
                                    <span class="mt-1">
                                        Registro de vulnerabilidades ou não conformidades para o registro de plano de ação integral - momento 2
                                    </span>
                                </div>
                                <svg v-if="!p.mostrar" @click="p.mostrar = !p.mostrar" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
                                <svg v-else @click="p.mostrar = !p.mostrar" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path></svg>
                            </div>

                            <div
                                class="grid grid-cols-1 p-4 gap-4" 
                                v-show="p.mostrar"
                            >
                                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 items-start gap-2">
                                    <section class="col-span-2 lg:col-span-4 grid grid-cols-1 md:grid-cols-2 gap-2">
                                        <div class="flex flex-col">
                                            1 - Vulnerabilidades/Não conformidades

                                            <div class="flex">
                                                <multiselect
                                                    v-model="p.vulnerabilidades"
                                                    :options="vulnerabilidades"
                                                    :multiple="true"
                                                    :close-on-select="false"
                                                    label="nome"
                                                    trackBy="_id"
                                                    @input="onSelectVulnerabilidadePAI($event, idx)" 
                                                    placeholder="selecione as vulnerabilidades"
                                                    selectedLabel="selecionado"
                                                    deselectLabel="Pressione Enter para remover"
                                                    selectLabel="Pressione Enter para selecionar"
                                                    class="mt-2"
                                                />
                                                <svg
                                                    @click="abrirModalVulnerabilidadePAI(idx)"
                                                    fill="#059669"
                                                    viewBox="0 0 256 256" 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="mx-1 mt-4 h-6 inline text-green-600"
                                                >
                                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="flex flex-col">
                                            2 - Condutas/controles a serem implementados

                                            <div class="flex">
                                                <multiselect
                                                    v-model="p.respostaNao"
                                                    :options="condutasMitigacao"
                                                    :multiple="true"
                                                    :close-on-select="false"
                                                    label="nome"
                                                    trackBy="_id"
                                                    placeholder="selecione as condutas"
                                                    selectedLabel="selecionado"
                                                    deselectLabel="Pressione Enter para remover"
                                                    selectLabel="Pressione Enter para selecionar"
                                                    class="mt-2"
                                                />
                                                <svg
                                                    @click="abrirModalCondutaPAI(idx)"
                                                    fill="#059669"
                                                    viewBox="0 0 256 256"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="mx-1 mt-4 h-6 inline text-green-600"
                                                >
                                                    <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="col-span-2 grid grid-cols-3 gap-2">
                                        <div class=" flex flex-col">
                                            <span>
                                                3 - Complexidade
                                            </span>
                                            <select v-model="p.complexidade" name="complexidade" id="complexidade"
                                                class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                                <option value="Baixo">Baixo</option>
                                                <option value="Médio">Médio</option>
                                                <option value="Alto">Alto</option>
                                            </select>
                                        </div>
                                        <div class=" flex flex-col">
                                            4 - Grau de risco
                                            <select v-model="p.grauDerisco" name="paigrauDerisco" id="paigrauDerisco"
                                                class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                                <option value="Muito Baixo">Muito Baixo</option>
                                                <option value="Baixo">Baixo</option>
                                                <option value="Médio">Médio</option>
                                                <option value="Alto">Alto</option>
                                                <option value="Muito Alto">Muito Alto</option>
                                            </select>
                                        </div>
                                        <div class="flex flex-col">
                                            5 - Aprovação
                                            <select v-model="p.condutaAprovada" name="condutaAprovada" id="condutaAprovada"
                                                class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                                <option value="Sim">Sim</option>
                                                <option value="Não">Não</option>
                                                <option value="Parcialmente">Parcialmente</option>
                                            </select>
                                        </div>
                                    </section>
                                </div>
                                <div class="flex gap-2 flex-wrap lg:flex-nowrap">
                                    <div class="flex flex-col">
                                        6 - Prazo de execução
                                        <div class="flex flex-wrap md:flex-nowrap space-x-1">
                                            <input v-model="p.prazoDeExecucao.de" type="date" class="block shadow-sm sm:text-sm border-gray-300 rounded-md text-gray-900">
                                            <input v-model="p.prazoDeExecucao.ate" type="date" class="block shadow-sm sm:text-sm border-gray-300 rounded-md text-gray-900">
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 gap-2 w-full">
                                        <div class="flex flex-col w-full">
                                            7 - Aprovador
                                            <input
                                                v-model="p.quemAprovou"
                                                placeholder="Digite o nome"
                                                type="text"
                                                class="text-black py-1.5 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                                            >
                                        </div>
                                        <div class="flex flex-col w-full">
                                            8 - Observações
                                            <textarea
                                                v-model="p.obs"
                                                class="rounded-md border-gray-300 sm:text-sm text-gray-900"
                                                name="obs" id="obs"
                                                rows="1"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                                    <div class="flex flex-col w-full" id="multiselect_responsaveis_conduta">
                                        9 - Responsaveis pela conduta

                                        <multiselect
                                            v-model="p.responsaveisPelaConduta"
                                            :options="agentesProfissionaisPessoas"
                                            :multiple="true"
                                            group-values="arr" group-label="tipo"
                                            :close-on-select="false"
                                            placeholder="Digite aqui para filtrar"
                                            tagPlaceholder=""
                                            noOptionsText=""
                                            selectedLabel=""
                                            deselectLabel=""
                                            selectLabel=""
                                            class="shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            track-by="_id" label="nome"
                                        >
                                            <template #tag="props">
                                                <span class="option__desc space-x-1">
                                                    <span :class="
                                                        {
                                                            'bg-yellow-400': props.option.tipo === 'Fornecedor',
                                                            'bg-blue-500': props.option.tipo === 'Profissional',
                                                            'bg-green-500': props.option.tipo === 'Pessoa',
                                                        }
                                                    " class="mx-1 p-1 text-white rounded-md">
                                                        {{ props.option.nome }}
                                                    </span>
                                                </span>
                                            </template>

                                            <template #option="props">
                                                <div 
                                                    :class="
                                                        {
                                                            'bg-yellow-400': props.option.tipo === 'Fornecedor',
                                                            'bg-blue-500': props.option.tipo === 'Profissional',
                                                            'bg-green-500': props.option.tipo === 'Pessoa',
                                                        }
                                                    "

                                                    class="option__desc p-1"
                                                >
                                                    <span class="option__title" v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
                                                    <span class="option__title" v-if="!props.option.$isLabel">{{ props.option.nome }}</span>
                                                </div>
                                            </template>

                                            <template #noResult>
                                                Não encontrou
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="flex flex-col">
                                        10 - Evidências
                                        <div class="w-full flex flex-col">
                                            <vue-dropzone
                                                ref="doc"
                                                id="doc"
                                                class="w-full rounded-lg"
                                                :options="dropzoneOptions"
                                                v-on:vdropzone-success="(file, response) => updateDoc(file, response, idx)"
                                                :style="{
                                                    minHeight: '80px',
                                                    height: '80px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    fontSize: '14px',
                                                    padding: '0'
                                                }"
                                            >
                                            </vue-dropzone>
                                            <table class="min-w-full divide-y divide-gray-200" v-if="p.evidencias && p.evidencias.length>0">
                                                <thead class="bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    <tr>
                                                        <th scope="col" class="px-6 py-3">
                                                            Arquivo
                                                        </th>
                                                        <th  scope="col" class="px-6 py-3">
                                                            Opção
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200 text-sm text-gray-500">
                                                    <tr 
                                                        v-for="(item, indexEvidencia) in p.evidencias" 
                                                        :key="indexEvidencia"
                                                    >
                                                        <td class="px-6 py-4 ">
                                                            {{item.originalname ? item.originalname : ''}}
                                                        </td>
                                                        <td class="px-6 py-4 flex gap-2 flex-wrap items-center">
                                                            <a 
                                                                class="text-blue-500"
                                                                target="_blank" 
                                                                :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">
                                                                Visualizar
                                                            </a>
                                                            <button
                                                                type="button"
                                                                @click="p.evidencias.splice(indexEvidencia, 1)" 
                                                                class="justify-center py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                                Remover
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="flex gap-2 col-span-1 md:col-span-2 lg:col-span-1 text-sm">
                                        <section class="flex flex-col">
                                            11 - Status
                                            <select v-model="p.status" name="status" id="status" @change="gerarDataConclusao(idx)"
                                                class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                                <option :value="status" v-for="status in opcoesStatusPAI" :key="status">
                                                    {{status}}
                                                </option>
                                            </select>
                                        </section>
                                        <section class="flex flex-col w-full">
                                            12 - Incluir no Plano de Ação Integral (PAI)
                                            <select 
                                                v-model="p.incluirPAI" 
                                                name="incluirPAI" id="incluirPAI"
                                                class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                                            >
                                                <option :value="true">Sim</option>
                                                <option :value="false" default>Não</option>
                                            </select>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="flex items-end gap-2 justify-end p-4" v-show="p.mostrar">
                                <button 
                                    type="button" 
                                    @click="salvarM2(p, idx)" 
                                    class="text-white text-center hover:bg-blue-800 bg-blue-700 rounded-md py-2 px-3 "
                                >
                                    Salvar controle
                                </button>
                                <button 
                                    type="button" 
                                    @click="removerPAI(p, idx)" 
                                    class="text-white text-center hover:bg-red-600 bg-red-500 rounded-md py-2 px-3 "
                                >
                                    Excluir registro
                                </button>
                            </div>
                        </section>
                    </div>

                    <div class="px-5 pb-4 flex pt-2">
                        <button 
                            type="button" 
                            @click="adicionarPAI" 
                            class="text-white hover:bg-blue-700 bg-blue-600 rounded py-1 px-3 my-1"
                        >
                            Novo registro
                        </button>
                    </div>

                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>

                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save()" v-if="!form.finalizado" type="button" class="inline-flex justify-center py-2 px-6 m-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                Salvar sem finalizar
                            </button>
                            <button v-if="$store.state.user.cliente" @click="save('respondido')" type="button" class="inline-flex justify-center py-2 px-6 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400">
                                Finalizar QMA
                            </button>
                            <button v-if="form.respondido && !form.revisado && !$store.state.user.cliente || !$store.state.user.cliente && !form.revisado && form.tipo_responsavelQMA === 'consultor'" @click="save('revisado')" type="button" class="inline-flex justify-center py-2 px-6 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                                Marcar como revisada
                            </button>
                            <button v-if="form.respondido && !$store.state.user.cliente || form && form.profissional_responsavelQMA && $store.state.user._id.toString() === form.profissional_responsavelQMA.toString() || !$store.state.user.cliente && form.tipo_responsavelQMA === 'consultor'" @click="save('finalizado')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Finalizar QMA
                            </button>
                        </div>
                    </div> 
                </form>
            </div>
        </div>
        <modal name="modalVulnerabilidadesPAI" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova Vulnerabilidade </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="nome" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItemPAI.nome"
                            type="text"
                            name="novaVulnerabilidadePAI"
                            id="novaVulnerabilidadePAI"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>

                <div class="grid grid-cols-2 mt-3">
                <div class="py-3 text-left">
                    <button @click="$modal.hide('modalVulnerabilidadesPAI')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="adicionarVulnerabilidadePAI" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
            </div>
        </modal>
        <modal name="modalCondutaPAI" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova conduta </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="nome" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItemPAI.nome"
                            type="text"
                            name="novaCondutaPAI"
                            id="novaCondutaPAI"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>
             
                <div class="grid grid-cols-2 mt-3">
                    <div class="py-3 text-left">
                        <button @click="$modal.hide('modalCondutaPAI')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="py-3 text-right">
                        <button @click="adicionarCondutaPAI" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="novoItem" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <div v-if="formNovo.url === 'finalidades'">
                    <h2 class="text-xl font-bold mb-4"> Nova Finalidade </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="titulo" class="block text-sm font-medium">Título finalidade</label>
                            <input v-model="formNovo.titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12">
                            <label for="baselegal" class="block text-sm font-medium">
                                Base legal 
                            </label>
                            <select required v-model="formNovo.baselegal" name="baselegal" id="baselegal" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option v-for="baselegal in baselegais" :key="baselegal._id" :value="baselegal._id">{{ baselegal.nome }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-if="formNovo.url === 'setores'">
                    <h2 class="text-xl font-bold mb-4"> Novo Setor </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'baselegais'">
                    <h2 class="text-xl font-bold mb-4"> Nova base legal </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12">
                            <label for="artigo" class="block text-sm font-medium">Artigo</label>
                            <input v-model="formNovo.artigo" type="text" name="artigo" id="artigo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'ativos'">
                    <h2 class="text-xl font-bold mb-4"> Novo Ativo </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'vulnerabilidades'">
                    <h2 class="text-xl font-bold mb-4"> Nova Vulnerabilidade </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'ameacas'">
                    <h2 class="text-xl font-bold mb-4"> Nova Ameaça</h2>
                    <div class="grid grid-cols-12 gap-4">
                        
                        <div class="col-span-12 lg:col-span-12">
                            <label for="name" class="block text-sm font-medium">Nome Ameça</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12 lg:col-span-12">
                            <label for="vulnerabilidade" class="block text-sm font-medium">Vulnerabilidade</label>
                            <multiselect v-model="formNovo.vulnerabilidade" :options="vulnerabilidades" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="vulnerabilidade" placeholder="selecione as vulnerabilidades" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-1"/>
                        </div>
                        <div class="col-span-12 lg:col-span-12">
                            <label for="condutaMitigacao" class="block text-sm font-medium">Conduta Mitigação</label>
                            <multiselect v-model="formNovo.condutaMitigacao" :options="condutasMitigacao" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="condutaMitigacao" placeholder="selecione as condutas de mitigação" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-1"/>
                        </div>
                    </div>
                </div>
                <div v-if="formNovo.url === 'tipotitulares'">
                    <h2 class="text-xl font-bold mb-4"> Novo Tipo de titular de dados </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="tipo" class="block text-sm font-medium">Tipo</label>
                            <input v-model="formNovo.tipo" type="text" name="tipo" id="tipo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'canais'">
                    <h2 class="text-xl font-bold mb-4"> Novo Canal de tratamento de dados </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'armazenamento'">
                    <h2 class="text-xl font-bold mb-4"> Novo Armazanamento </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'medidasSeguranca'">
                    <h2 class="text-xl font-bold mb-4"> Nova medida segurança </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12">
                            <label for="descricao" class="block text-sm font-medium">Descrição</label>
                            <textarea v-model="formNovo.descricao" rows="5" type="text" name="descricao" id="descricao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div> 
                        <div class="col-span-12">
                            <select v-model="formNovo.tipo" name="tipo" id="tipo" class="col-span-12 lg:col-span-12 mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="tecnica">Técnica</option>
                                <option value="administrativa">Organizacional / Administrativa</option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'medidasSegurancaTecnica'">
                    <h2 class="text-xl font-bold mb-4"> Nova medida segurança Técnica </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12">
                            <label for="descricao" class="block text-sm font-medium">Descrição</label>
                            <textarea v-model="formNovo.descricao" rows="5" type="text" name="descricao" id="descricao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div> 
                        <div class="col-span-12">
                            <select v-model="formNovo.tipo" name="tipo" id="tipo" class="col-span-12 lg:col-span-12 mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="tecnica">Técnica</option>
                                <option value="administrativa">Organizacional / Administrativa</option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'legislacoes'">
                    <h2 class="text-xl font-bold mb-4"> Nova Legislação </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12">
                            <label for="artigo" class="block text-sm font-medium">Artigos específicos</label>
                            <input v-model="formNovo.artigo" type="text" name="artigo" id="artigo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">                 
                        </div>
                        <div class="col-span-12">
                            <label for="tema" class="block text-sm font-medium">Tema ou assunto</label>
                            <input v-model="formNovo.tema" type="text" name="tema" id="tema" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12">
                            <label for="negocios" class="block text-sm font-medium">Negócio</label>
                            <input v-model="formNovo.negocio" type="text" name="negocio" id="negocio" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">                 
                        </div>
                    </div>
                </div>
                <div v-if="formNovo.url === 'dados'">
                    <h2 class="text-xl font-bold mb-4"> Novo dado </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12">
                            <label for="categoria" class="block text-sm font-medium">Categoria</label>
                            <select required v-model="formNovo.categoria" name="categoria" id="categoria" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option v-for="categoria in categorias" :key="categoria._id" :value="categoria._id">{{ categoria.nome }}</option>
                            </select>
                        </div>
                        <div class="col-span-12">
                            <label for="sensivel" class="block text-sm font-medium">
                                <input type="checkbox" class="rounded-sm" id="sensivel" v-model="formNovo.sensivel">
                                <span class="ml-2"> Considerado dado sensível </span>
                            </label>
                        </div>
                        <div class="col-span-12">
                            <label for="critico" class="block text-sm font-medium">
                                <input type="checkbox" class="rounded-sm" id="critico" v-model="formNovo.critico">
                                <span class="ml-2"> Considerado dado crítico </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="formNovo.url === 'pessoas'">
                    <h2 class="text-xl font-bold mb-4"> Nova Pessoa na empresa </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12">
                            <label for="email" class="block text-sm font-medium">Email * </label>
                            <input v-model="formNovo.email" type="email" name="email" id="email" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                        <div class="col-span-12">
                            <label for="password" class="block text-sm font-medium">Senha *</label>
                            <input v-model="formNovo.password" type="password" name="password" id="password" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12">
                            <label for="setor" class="block text-sm font-medium">Setor</label>
                            <select required v-model="formNovo.setor" name="setor" id="setor" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div v-if="formNovo.url === 'fornecedores'">
                    <h2 class="text-xl font-bold mb-4"> Novo Agente Tratamento (Fornecedor) </h2>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 lg:col-span-4">
                            <label for="agenteTratamento" class="block text-sm font-medium">Agente</label>
                            <select required v-model="formNovo.agente" name="agenteTratamento" id="agenteTratamento" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                            </select>
                        </div> 
                        <div class="col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome</label>
                            <input v-model="formNovo.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12">
                            <label for="cnpj" class="block text-sm font-medium">CNPJ</label>
                            <input v-model="formNovo.cnpj" type="text" name="cnpj" id="cnpj" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12">
                            <label for="responsavel" class="block text-sm font-medium">Responsável</label>
                            <input v-model="formNovo.responsavel" type="text" name="responsavel" id="responsavel" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 mt-3">
                <div class="py-3 text-left">
                    <button @click="$modal.hide('novoItem')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="criarItem" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
            </div>
        </modal>
        <modal name="modalFaseCiclo" :adaptive="true" :height="'auto'">
            <div id="idmodal" class="px-3 py-5 overflow-auto h-full md:h-auto">
                <h3 class="text-base font-bold mb-3">Fases do ciclo de tratamento (LGPD, ART.5º, X)</h3>
                <p class="text-sm font-medium">
                    <b class="text-base">Coleta:</b> Coleta, produção e recepção.
                </p>
                <p class="text-sm font-medium mt-1">
                    <b class="text-base">Retenção:</b> Arquivamento e armazenamento.
                </p>
                <p class="text-sm font-medium mt-1">
                    <b class="text-base">Processamento:</b> Classificação, utilização, reprodução, processamento, avaliação ou controle da informação, extração e modificação.
                </p>
                <p class="text-sm font-medium mt-1">
                    <b class="text-base">Compartilhamento:</b> Transmissão, distribuição, comunicação, transferência e difusão.
                </p>
                <p class="text-sm font-medium mt-1">
                    <b class="text-base">Eliminação:</b> Eliminação.
                </p>
            </div>
        </modal>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import  moment from 'moment';

export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            inicialmenteDesativada: false,
            route_navigation: 'mapeamento',
            route_backend: 'atividades',
            finalidades: [],
            preatividades: [],
            preatividade_setor: null,
            agentesTratamento: [],
            ativos: [],
            ameacas: [],
            vulnerabilidades: [],
            condutasMitigacao: [],
            setores: [],
            pessoas: [],
            categorias: [],
            profissionais: [],
            dados: [],
            dadossensiveis: [],
            categoriasdados: [],
            tipotitulares: [],
            canais: [],
            baselegais: [],
            legislacoes: [],
            armazenamentos: [],
            empresas: [],
            medidasSeguranca: [],
            medidasSegurancaTecnica: [],
            fornecedores: [],
            fasesdados: [
                'Coleta',
                'Compartilhamento',
                'Eliminação',
                'Processamento',
                'Retenção',
            ],
            tratamentodados: [
                'acesso',                                                               
                'arquivamento',                                                               
                'armazenamento',                                                               
                'avaliação ou controle da informação',                                                               
                'classificação',                                                               
                'coleta',                                                              
                'comunicação',                                                               
                'difusão ou extração',
                'distribuição',                                                               
                'eliminação',                                                               
                'modificação',                                                               
                'processamento',                                                               
                'produção',                                                               
                'recepção',                                                               
                'reprodução',                                                               
                'transferência',                                                               
                'transmissão',                                                               
                'utilização',                                                               
            ],
            formNovo: {},
            novoItemPAI: {
                nome: '',
                index: null,
            },
            opcoesStatusPAI: ['Pendente','Em andamento', 'Concluído'],
            agentesProfissionaisPessoas: [],
            form: {
                nome: '',
                descricao: '',
                versao: '',
                considerarLegitimoInteresse: false,
                agente: null,
                empresa: null,
                setor: null,
                finalidade: null,
                responsavel: null,
                pessoa_responsavelQMA: null,
                profissional_responsavelQMA:  null,
                tipo_responsavelQMA: '',
                consultor_responsavelQMA: null,
                entrevistado: null,
                tipotratamentodados: [], 
                fasesdados: [],
                formatodadopessoal: null, 
                dados: null,
                dadossensiveis: null,
                categoriasdados: null,
                tipotitular: [],
                canais: null,
                qtd: '',
                frequencia: '',
                outraFrequencia: '',
                titularmenor: false,
                consetimentopais: false,
                baselegal: [],
                legislacoes: null,
                testeInteresse: false,
                armazenamento: null,
                prazoRetencao: 0,
                tipoPrazoRetencao: '',
                formaDescarte: '',
                origemAcesso: false,
                origemAcessoPessoas: [],
                comoOrigemAcessoPessoas: '',
                compartilhadaSetor: false,
                comoCompartilhadaSetor: '',
                setoresCompartilhado: [],
                compartilhadaGrupoEconomico: false,
                grupoEconomicoCompartilhado: [],
                compartilhadaEmpresa: false,
                empresasCompartilhado: [],
                compartilhadaEmpresaEstrangeira: false,
                empresasEstrangeiraCompartilhada: [],
                decisaoAutomatizada: false,
                descricaoDecisaoAutomatizada: '',
                medidasSeguranca: [],
                medidasSegurancaTecnica: [],
                finalizado: false,
                infoPreliminares: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                dadosColetados: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                legitimidade: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                cicloDeVida: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                acessoDados: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                medidas: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                gestaoDeRisco: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                obsConformidades: '',
                principiosAtendidos: [
                    {
                        "entende": false,
                        "principio": "I",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "II",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "III",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "IV",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "V",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VI",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VII",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VIII",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "IX",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "X",
                        "obs": ""
                    },
                ],
                avaliacao_preliminar: {
                    ativos: [],
                    vulnerabilidades: [],
                    ameacas: [],
                    condutas: [],
                    probabilidade: '',
                    impacto: '',
                    grauderisco: '',
                    obs: '',
                },
                microatividades: [],
                orientacoes: [
                    { data: '', texto: '', quem: '' },
                ],
                quantidadeTitulares: [],
                anexosEvidencias: [],
                pai: [],
            },
            pai: {
                mostrar: false,
                incluirPAI: false,
                evidencias: [],
                grauDerisco: 'Baixo',
                complexidade: "Baixo",
                condutaAprovada: 'Não',
                prazoDeExecucao: {
                    de: null,
                    ate: null,
                },
                obs: '',
                responsaveisPelaConduta: [],
                status: "Pendente",
                concluidoEm: null,
                quemAprovou:'',
                vulnerabilidades: [],
                respostaNao: [],
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 2,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Anexe aqui evidências da conduta realizada/controle aplicado',
                dictRemoveFile: 'Remover',
                acceptedFiles: '.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.odt,.ods,.odp,.odg,.odf'
            }),
            tooltip: {
                '2.1-w': `
                        Embora os consultores/DPOs/profissionais <br/>
                        tenham a liberdade de adicionar quantos perfis de titulares <br/>
                        considerarem necessários, recomendamos que seja <br/>
                        indicado apenas um perfil de titular para cada atividade <br/>
                        mapeada. Isso facilitará o rastreamento dos dados em caso <br/>
                        de necessidade de resposta ao titular.
                    `,
                '2.6-w': `
                        Como consultor, você tem a liberdade de identificar <br/> quantas bases 
                        legais são necessárias para uma determinada atividade.<br/> No entanto, na 
                        FACIL, recomendamos que cada atividade seja vinculada <br/> a uma finalidade 
                        específica e essa finalidade esteja relacionada<br/> a uma hipótese de 
                        tratamento/bases legaais.
                    `,
                '1.8': `
                Controlador: Pessoa natural ou jurídica, de direito público ou privado, <br/> a quem competem as decisões referentes ao tratamento de dados pessoais.<br/><br/> 
                Operador: Pessoa natural ou jurídica, de direito público ou privado, <br/> que realiza o tratamento de dados pessoais em nome do controlador.<br/><br/> 
                Suboperador: suboperador é aquele contratado pelo operador para auxiliá-lo<br/>  a realizar o tratamento de dados pessoais em nome do controlador.<br/> <br/> 
                Controlador conjunto: <br/> É “a determinação conjunta, comum ou convergente, por dois ou mais controladores,<br/>  das finalidades e dos elementos essenciais para a realização do tratamento de dados pessoais, <br/> 
                por meio de acordo que estabeleça as respectivas responsabilidades quanto ao cumprimento da <a target="_blank" href="">LGPD.</a>"`,
                
                "1.9": `
                A detecção da volumetria dos dados pessoais é importante <br/>
                porque permite compreender a quantidade de dados envolvidos <br/>
                em uma atividade de tratamento, auxiliando na gestão adequada, <br/>
                na implementação de medidas de segurança e no cumprimento das <br/>
                obrigações legais relacionadas à proteção de dados.`,

                "1.10": `
                Um processo com decisão automatizada é quando uma máquina<br/>
                toma uma decisão sozinha, sem a ajuda de pessoas. <br/>
                A LGPD é uma lei que protege nossos dados pessoais <br/>
                e nos dá o direito de saber como essas decisões <br/>
                são feitas e de contestá-las se não concordarmos com elas.`,

                "1.11": `
                Se você entender que dentro de um processo, <br/>
                existam vários subprocessos, convém que sejam subdivididos  <br/>
                dando origem a novas atividades que também deverão ser mapeadas. <br/>
                Ao gerar uma nova atividade ela aparecerá na página inicial <br/>
                do módulo de mapeamento.`,

                "2.1": `
                Por favor, especifique no campo abaixo qual é o perfil do titular<br/>
                dos dados pessoais para esta atividade. Caso haja mais de um perfil, <br/>
                recomendamos duplicar a atividade <br/>
                por meio das opções disponíveis na tela inicial do módulo.`,

                "2.2": `Dado pessoal: informação relacionada a pessoa natural identificada ou identificáve (Art. 5º I) `,

                "2.3": `
                Dado pessoal sensível: dado pessoal sobre origem racial ou étnica, <br/>
                convicção religiosa, opinião política, filiação a sindicato ou <br/>
                a organização de caráter religioso, filosófico ou político, <br/>
                dado referente à saúde ou à vida sexual, dado genético ou biométrico, <br/>
                quando vinculado a uma pessoa natural (Art. 5º II).`,

                "2.4": `As categorias de dados pessoais são classificações que ajudam a identificar diferentes tipos de informações pessoais`,
                "2.5": `<p class="w-96">Atribuir uma finalidade para um dado pessoal é importante porque isso determina o propósito pelo qual essa informação está sendo coletada e utilizada. Isso garante transparência e controle sobre nossos dados, além de assegurar que eles sejam usados apenas de acordo com os nossos interesses e direitos</p>`,
                "2.6": `<p class="w-96">Uma hipótese de tratamento ou base legal, de acordo com a Lei Geral de Proteção de Dados (LGPD), é o fundamento legal que permite o tratamento de dados pessoais. Elas são importantes para garantir que o tratamento de dados seja realizado de forma legal e legítima.</p>`,
                "2.7": `<p class="w-96">Legislação  que autoriza o dado pessoal a ser tratado na atividade usando a hipotese da "obrigação legal"</p>`,
                "2.8": `<p class="w-96">O teste de legítimo interesse é um processo usado para avaliar se o tratamento de dados pessoais é justificado com base em interesses legítimos do controlador dos dados. Ele é obrigatório pela Lei Geral de Proteção de Dados (LGPD) para garantir que o tratamento de dados seja realizado de forma equilibrada, respeitando os direitos e privacidade dos titulares dos dados. O teste ajuda a assegurar que os interesses do controlador não prevaleçam sobre os direitos fundamentais dos indivíduos.</p>`,
                "2.9": `<p class="w-96">Tratamento de dados, de acordo com a LGPD, abrange qualquer operação realizada com dados pessoais, incluindo coleta, armazenamento, uso, compartilhamento, entre outros. É importante ressaltar que o tratamento de dados não deve ser limitado apenas à coleta, mas sim compreendido como um conjunto de ações que envolvem o processamento dessas informações, garantindo a proteção da identidade e privacidade das pessoas em conformidade com a legislação de proteção de dados.</p>`,
                "2.10": `<p class="w-96">Um dado pessoal pode ser tratado em formato físico, como um documento em papel, ou em formato digital, como um registro em um banco de dados, um arquivo de texto, uma planilha eletrônica ou uma imagem digital.</p>`,
                "2.11": `<p class="w-96">Mapear a quantidade de titulares envolvidos em uma atividade é importante para o consultor entender o impacto e a escala das operações de processamento de dados. Isso ajuda na avaliação de riscos, na implementação de medidas de segurança apropriadas e no cumprimento das regulamentações de proteção de dados.</p>`,
                "2.12": `<p class="w-96">Saber a abrangência geográfica dos titulares envolvidos em uma atividade é importante para determinar as leis de proteção de dados aplicáveis, entender os requisitos de transferência internacional de dados e garantir a conformidade com as regulamentações locais e internacionais.</p>`,
                "2.13": `<p class="w-96">Identifique as portas de entrada para os dados, bem como o fluxo interno e, se houver, canais externos de compartilhamento ou transferência dessas informações. Os exemplos de canais incluem as opções abaixo mencionadas.</p>`,
                "2.14": `<p class="w-96">O local de armazenamento ou arquivamento de dados pessoais se refere ao lugar físico ou virtual onde as informações pessoais são mantidas, como servidores de nuvem, data centers, arquivos físicos ou dispositivos de armazenamento.</p>`,
                "2.15": `<p class="w-96">É importante indicar um prazo de retenção para documentos e dados pessoais para garantir a conformidade com as regulamentações de proteção de dados, evitar o armazenamento desnecessário e proteger a privacidade dos titulares.</p>`,
                "2.16": `<p class="w-96">Padronizar a forma de descarte de um dado pessoal é importante para garantir que os dados sejam eliminados de maneira segura, evitando riscos de vazamento, violações de privacidade e não conformidade com as regulamentações de proteção de dados.</p>`,
                "2.17": `<p class="w-96">Ao tratar dados de uma pessoa menor de idade, é importante obter consentimento de pelo menos um dos dos pais ou responsáveis legalmente constituidos, garantir a privacidade e segurança dos dados, e cumprir as regulamentações específicas para proteção de crianças.</p>`,
                "2.18": `<p class="w-96">O consentimento de um dos pais ou do responsável legalmente constituído para o tratamento de dados pessoais de uma criança, de acordo com a Lei Geral de Proteção de Dados brasileira, deve ser específico, destacado e obtido por escrito ou por outro meio que demonstre a manifestação inequívoca do consentimento.</p>`,
                "2.19": `<p class="w-96">Ao tratar dados de idosos ou vulneráveis, é importante garantir a privacidade e segurança dos dados, obter consentimento explícito quando necessário, proteger contra abusos ou exploração, e cumprir as regulamentações de proteção de dados, como a Lei Geral de Proteção de Dados (LGPD).</p>`,
                "2.20": `<p class="w-96">Ao tratar dados de idosos ou vulneráveis, é importante garantir a privacidade e segurança dos dados, obter consentimento explícito quando necessário, proteger contra abusos ou exploração, e cumprir as regulamentações de proteção de dados, como a Lei Geral de Proteção de Dados (LGPD).</p>`,
                "4.1": `<p class="w-96">As medidas técnicas referem-se a controles e dispositivos tecnológicos, como criptografia, firewalls e autenticação, enquanto as medidas organizacionais envolvem políticas, procedimentos e treinamento de pessoal para garantir a segurança e privacidade dos dados pessoais.</p>`,
                "4.2": `<p class="w-96">As medidas técnicas referem-se a controles e dispositivos tecnológicos, como criptografia, firewalls e autenticação, enquanto as medidas organizacionais envolvem políticas, procedimentos e treinamento de pessoal para garantir a segurança e privacidade dos dados pessoais.</p>`,
                "4.3": `<p class="w-96">É fundamental que, em toda atividade que envolva dado pessoal, todos os princípios da LGPD estejam contemplados</p>`,
                "4.5": `<p class="w-96">A avaliação preliminar de riscos, neste momento, é importante pois as informações sobre a atividade estão “frescas” na memória do profissional. Tudo que for salvo aqui aparecerá no momento da análise de risco definitiva desta atividade lá no módulo de “Gestão de riscos”.</p>`
            },
            abas: [
                {
                    nome: 'Informações iniciais sobre a atividade / processo',
                    visible: true,
                },
                {
                    nome: 'Sobre os dados e seu ciclo de vida',
                    visible: false,
                },
                {
                    nome: 'Sobre compartilhamento / transferência dos dados',
                    visible: false,
                },
                {
                    nome: 'Sobre medidas de segurança, risco preliminar e outros detalhes',
                    visible: false,
                },
                {
                    nome: 'Conversas / orientações sobre a atividade',
                    visible: false,
                    semNumero: true,
                },
            ],
            moment
        }
    },
    methods: {
        abrirModalVulnerabilidadePAI(idx) {
            this.novoItemPAI.index = idx;
            this.$modal.show('modalVulnerabilidadesPAI');
        },
        abrirModalCondutaPAI(idx) {
            this.novoItemPAI.index = idx;
            this.$modal.show('modalCondutaPAI');
        },
        async adicionarVulnerabilidadePAI() {
            const index = this.novoItemPAI.index

            const req = await this.$http.post(`/v1/vulnerabilidades`, this.novoItemPAI);
            if (req.data.success) {
                this.$vToastify.success("Vulnerabilidade adicionada com sucesso!");
                this.$modal.hide('modalVulnerabilidadesPAI');

                this.vulnerabilidades.push(req.data.data);
                this.form.pai[index].vulnerabilidades.push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A vulnerabilidade já existe`
                return this.$vToastify.error(error);
            }

            this.novoItemPAI.index = null;
            this.novoItemPAI.nome = '';
        },
        async adicionarCondutaPAI() {

            const index = this.novoItemPAI.index

            const req = await this.$http.post(`/v1/condutasmitigacao`, this.novoItemPAI);
            if (req.data.success) {
                this.$vToastify.success("Conduta adicionada com sucesso!");
                this.$modal.hide('modalCondutaPAI');

                this.condutasMitigacao.push(req.data.data);
                this.form.pai[index].respostaNao.push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A conduta já existe`
                return this.$vToastify.error(error);
            }

            this.novoItemPAI.index = null;
            this.novoItemPAI.nome = '';
        },
        onSelectVulnerabilidadePAI (itens, index) {
            itens?.forEach(item => {
                for (let i = 0; i < item.condutas?.length; i++){
                    const c = item.condutas[i];
                    if (!this.form.pai[index].respostaNao.some(cdt => cdt.nome === c.nome)) {
                        this.form.pai[index].respostaNao.push(c);
                    }
                }
            });
        },
        gerarDataConclusao(idx){
            if(this.form.pai.length && this.form.pai[idx].status === 'Concluído'){
                this.form.pai[idx].concluidoEm = new Date();
            }else{
                this.form.pai[idx].concluidoEm = null;
            } 
        },
        adicionarPAI(){
            this.form.pai.push(JSON.parse(JSON.stringify(this.pai)));
        },
        async removerPAI(item, idx){

            if(!this.form._id) return this.$vToastify.error(`A atividade de mapeamento ainda não foi salva.`);

            if(!item._id) return this.form.pai.splice(idx, 1)

            this.$confirm({
                title: 'Deseja excluir registro?',
                message: ` Você não poderá reverter isso!`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if(confirm){
                        const req = await this.$http.put(`/v1/${this.route_backend}/excluirM2`,{
                            _id: this.form._id,
                            pai: item,
                        });
                        if (req.data && req.data.success) {
                            this.form.pai.splice(idx, 1)
                            this.$vToastify.success(`Registro excluido com sucesso`);
                        }
                        else this.$vToastify.error(`Erro ao excluir registro`);
                    }
                }
            });
        },
        async salvarM2(item, idx){

            if(!this.form._id) return this.$vToastify.error(`A atividade de mapeamento ainda não foi salva.`);

            const req = await this.$http.put(`/v1/${this.route_backend}/salvarM2`,{
                _id: this.form._id,
                pai: item,
            });
            if (req.data && req.data.success) {
                this.$vToastify.success(`Controle salvo com sucesso`);
            }
            else this.$vToastify.error(`Erro ao salvar controle ${idx+1}`);
        },
        async updateDoc(file, response, idx) {
            if (this.form.pai[idx]) this.form.pai[idx].evidencias.push(response.file);
            else console.error(`Índice ${idx} não encontrado em form.pai`);
        },
        updateQtdTipoTitular() {

            const indexToRemove = [];

            for (let i = 0; i < this.form.quantidadeTitulares.length; i++) {
                const qtdTipo = this.form.quantidadeTitulares[i];

                const result = this.form.tipotitular.find(t => t._id === qtdTipo?.tipo?._id);
                if (!result) {
                    indexToRemove.push(i);
                }
            }

            for (let i = 0; i < indexToRemove.length; i++) {
                const index = indexToRemove[i];
                this.form.quantidadeTitulares.splice(index, 1);
            }

            for (let i = 0; i < this.form.tipotitular.length; i++) {
                const tipo = this.form.tipotitular[i];
                
                if (!this.form.quantidadeTitulares.find(t => t.tipo._id === tipo._id)) {
                    this.form.quantidadeTitulares.push({
                        tipo: tipo,
                        qtd: tipo.qtd,
                    });
                }
            }
        },
        onSelectTipoTitular() {
            this.updateQtdTipoTitular();
        },
        dateFrom_id(objectId) {
            if (!objectId) return '';
            return moment(new Date(parseInt(objectId.substring(0, 8), 16) * 1000)).format("DD/MM/YYYY");
        },
        mostraTextoFinalidade() {
            return this.finalidades?.find(f => f._id === this.form?.finalidade)?.titulo;
        },
        async apagarMicroatividade(index) {
            const microatividade = this.form.microatividades[index];
            await this.$http.post(`/v1/${this.route_backend}/apagarmicroatividade`, microatividade);

            this.form.microatividades.splice(index, 1);

            await this.save('micro');
        },
        async gerarMicroatividade(index) {
            const microatividade = this.form.microatividades[index];
            if (microatividade.atividadeGerada) {
                this.$vToastify.error("Atividade já foi gerada!");
            } else {
                const req = await this.$http.post(`/v1/${this.route_backend}/criarmicroatividade`, { microatividade });
                microatividade.atividadeGerada = req.data.atividadeGerada;
                this.$set(this.form.microatividades, index, microatividade);
                
                await this.save('micro');
            }
        },
        getImpacto(){
            switch (this.form.avaliacao_preliminar.impacto) {
                case "muitobaixa": return 5;
                case "baixo": return 10;
                case "medio": return 15;
                case "alto": return 20;
                case "muitoalto": return 25;
            }
        },
        getProbabilidade(){
            switch (this.form.avaliacao_preliminar.probabilidade) {
                case "muitobaixa": return 5;
                case "baixo": return 10;
                case "medio": return 15;
                case "alto": return 20;
                case "muitoalto": return 25;
            }
        },
        calcularGrauDeRisco() {
            const risco = this.getImpacto() * this.getProbabilidade();
            return risco;
        },
        statusGrauDeRisco(){
            if(!this.form.avaliacao_preliminar.impacto && !this.form.avaliacao_preliminar.probabilidade) return;
            
            const grau = this.calcularGrauDeRisco();

            if (grau <= 50){
                this.form.avaliacao_preliminar.grauderisco = 'muitobaixa';
            } else if (grau <= 150){
                this.form.avaliacao_preliminar.grauderisco = 'baixo';
            } else if (grau <= 250){
                this.form.avaliacao_preliminar.grauderisco = 'medio';
            } else if (grau <= 400){
                this.form.avaliacao_preliminar.grauderisco = 'alto';
            } else if (grau > 400){
                this.form.avaliacao_preliminar.grauderisco = 'muitoalto';
            }
        },
        getNome(id, colecao) {
            const encontrou = colecao.find(s => s._id === id);

            if (encontrou) {
                return encontrou.nome;
            }

            return '';
        },
        setAba(index) {
            this.abas.forEach((a,i) => {
                a.visible = false;
                if (i === index) a.visible = true;
            });
        },
        async save(opcao) {
            const method = this.form._id ? 'put' : 'post';
           
            if(opcao === 'revisado') {
                this.form.revisado = true;
                this.form.status = 'Revisado';
            }
            
            if(opcao === 'finalizado') {
                this.form.finalizado = true;
                this.form.revisado = true;
                this.form.status = 'Finalizado';
            }

            if(opcao === 'respondido') {
                this.form.respondido = true;
            }

            if(this.form.tipo_responsavelQMA === 'consultor') {
                this.form.profissional_responsavelQMA = null;
                this.form.pessoa_responsavelQMA = null;
            }

            if(this.form.tipo_responsavelQMA === 'profissional') {
                this.form.pessoa_responsavelQMA = null;
            }

            if(this.form.tipo_responsavelQMA === 'pessoa') {
                this.form.profissional_responsavelQMA = null;
            }

            for(let i = 0;i < this.form.pai.length; i++){
                if(this.form.pai[i].prazoDeExecucao && this.form.pai[i].prazoDeExecucao.de){
                    this.form.pai[i].prazoDeExecucao.de = moment(this.form.pai[i].prazoDeExecucao.de).startOf("day").toDate()
                }
                if(this.form.pai[i].prazoDeExecucao && this.form.pai[i].prazoDeExecucao.ate){
                    this.form.pai[i].prazoDeExecucao.ate = moment(this.form.pai[i].prazoDeExecucao.ate).startOf("day").toDate()
                }
            }            

            if(opcao === 'micro'){
                const req = await this.$http[method](`/v1/${this.route_backend}`, this.form);
                if (!req.data.success) {
                    this.$vToastify.error(req.data.err);
                }
                
                return;
            }

            if(opcao !== 'finalizado'){
                const req = await this.$http[method](`/v1/${this.route_backend}`, this.form);
                if (req.data.success) {
                    this.$vToastify.success("Salvo com sucesso!");
                    this.$router.push({path: '/mapeamento'});
                } else {
                    this.$vToastify.error(req.data.err);
                }
                return;
            }

            this.$confirm({
                title: 'Finalizar atividade',
                message: `Deseja marcar a atividade como finalizada ?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    this.form.finalizado = confirm;
                    const req = await this.$http[method](`/v1/${this.route_backend}`, this.form);
                     if (req.data.success) {
                        this.$vToastify.success("Salvo com sucesso!");
                        this.$router.push({path: '/mapeamento'});
                    } else {
                        this.$vToastify.error(req.data.err);
                    }
                }
            });
            
        },
        async updateAnexosEvidencias(file, response) {
            this.$refs.anexosEvidencias.removeFile(file);
            this.form.anexosEvidencias.push(response.file);
        },
        updateDocinfoPreliminares(file, response) {
            this.form.infoPreliminares.evidencias.push(response.file);
            this.$refs.infoPreliminares.removeAllFiles();
        },
        updateDocdadosColetados(file, response) {
            this.form.dadosColetados.evidencias.push(response.file);
            this.$refs.dadosColetados.removeAllFiles();
        },
        updateDoclegitimidade(file, response) {
            this.form.legitimidade.evidencias.push(response.file);
            this.$refs.legitimidade.removeAllFiles();
        },
        updateDoccicloDeVida(file, response) {
            this.form.cicloDeVida.evidencias.push(response.file);
            this.$refs.cicloDeVida.removeAllFiles();
        },
        updateDocacessoDados(file, response) {
            this.form.acessoDados.evidencias.push(response.file);
            this.$refs.acessoDados.removeAllFiles();
        },
        updateDocmedidas(file, response) {
            this.form.medidas.evidencias.push(response.file);
            this.$refs.medidas.removeAllFiles();
        },
        updateDocgestaoDeRisco(file, response) {
            this.form.gestaoDeRisco.evidencias.push(response.file);
            this.$refs.gestaoDeRisco.removeAllFiles();
        },
        updateTratamentodados(){
            let fases = [];
            for(let i = 0; i < this.form.tipotratamentodados.length; i++){
                const tipo = this.form.tipotratamentodados[i];
                if(tipo === 'coleta' || tipo === 'produção' || tipo === 'recepção') {
                    if(fases.indexOf('Coleta') === -1) fases.push('Coleta');
                }
                if(tipo === 'arquivamento' || tipo === 'armazenamento') {
                    if(fases.indexOf('Retenção') === -1) fases.push('Retenção');
                }
                if(tipo === 'classificação' || tipo === 'utilização' || tipo === 'reprodução' || tipo === 'processamento' || tipo === 'avaliação ou controle da informação' || tipo === 'modificação' || tipo === 'difusão ou extração') {
                    if(fases.indexOf('Processamento') === -1) fases.push('Processamento');
                }
                if(tipo === 'transmissão' || tipo === 'distribuição' || tipo === 'comunicação' || tipo === 'difusão ou extração') {
                    if(fases.indexOf('Compartilhamento') === -1) fases.push('Compartilhamento');
                }
                if(tipo === 'eliminação') {
                    if(fases.indexOf('Eliminação') === -1) fases.push('Eliminação');
                }
            }
            this.form.fasesdados = fases;
        },
        customLabelTratamentos(str) {
            return str.replace(/^./, str[0].toUpperCase());
        },
        customLabel ({ nome, sensivel, critico })  {
            return `${nome}${sensivel ? ' - DADO SENSÍVEL': ''}${critico ? ' - DADO CRÍTICO': ''}`
        },
        onDadoRemove(item) {
            const indexCat = this.form.categoriasdados.findIndex(cat => cat._id === item.categoria._id)
            this.form.categoriasdados.splice(indexCat, 1)

            setTimeout(()=>{
                this.form.dados?.forEach(d => {
                    if (d?.categoria) {
                        if (!this.form.categoriasdados.some(cat => cat._id === d.categoria._id)) {
                            this.form.categoriasdados.push(d.categoria);
                        }
                    }
                });
    
                this.form.dadossensiveis?.forEach(d => {
                    if (d?.categoria) {
                        if (!this.form.categoriasdados.some(cat => cat._id === d.categoria._id)) {
                            this.form.categoriasdados.push(d.categoria);
                        }
                    }
                });
            }, 1000)
        },
        onSelect (itens) {
            setTimeout(function() {
                const tags = document.getElementsByClassName('multiselect__tag');
                for(let i = 0; i < tags.length; i++){
                    const tag = tags[i];
                    if(tag.children[0].innerHTML.match('CRÍTICO')){
                        tag.style.backgroundColor = "#f0ad4e";
                    }
                    if(tag.children[0].innerHTML.match('SENSÍVEL')){
                        tag.style.backgroundColor = "#d9534f";
                    }
                }
            }, 500);

            itens?.forEach(item => {
                if (item?.categoria) {
                    if (!this.form.categoriasdados.some(cat => cat._id === item.categoria._id)) {
                        this.form.categoriasdados.push(item.categoria);
                    }
                }
            });
        },
        onSelectFinalidade (itens) {
            for (let i = 0; i < itens.length; i++) {
                const finalidade = itens[i];
                
                if (finalidade?.legislacoes?.length) {
                    for (let k = 0; k < finalidade?.legislacoes.length; k++) {
                        const l = finalidade?.legislacoes[k];

                        const legis = this.legislacoes.find((e) => e._id.toString() === l.toString());

                        if (legis) {
                            const jatem = this.form.legislacoes.find((e) => e._id.toString() === legis._id.toString());

                            if (!jatem) {
                                this.form.legislacoes.push(legis);
                            }
                        }

                    }
                }

                if (finalidade.baselegal?._id) {
                    const base = this.baselegais.find((e) => e._id.toString() === finalidade.baselegal?._id.toString());

                    if (base) {
                        const jatem = this.form.baselegal.find((e) => e._id.toString() === base._id.toString());
                        
                        if (!jatem) {
                            this.form.baselegal.push(base);
                        }
                    }
                }
            }
        },
        onSelectVulnerabilidade (itens) {
            itens?.forEach(item => {
                for (let i = 0; i < item.ameacas.length; i++){
                    const a = item.ameacas[i];
                   
                    if (!this.form.avaliacao_preliminar.ameacas.some(ame => ame.nome === a.nome)) {
                        this.form.avaliacao_preliminar.ameacas.push(a);
                    }
                }

                for (let i = 0; i < item.condutas.length; i++){
                    const c = item.condutas[i];
                   
                    if (!this.form.avaliacao_preliminar.condutas.some(cdt => cdt.nome === c.nome)) {
                        this.form.avaliacao_preliminar.condutas.push(c);
                    }
                }
            });
        },
        setValidacoes () {
            if (!this.form.titularmenor) {
                this.form.consetimentopais = false;
            }

            if (!this.form.origemAcesso) {
                this.form.origemAcessoPessoas = [];
                this.form.comoOrigemAcessoPessoas = '';
            }

            if (!this.form.compartilhadaSetor) {
                this.form.setoresCompartilhado = [];
                this.form.comoCompartilhadaSetor = '';
            }

            if (!this.form.compartilhadaEmpresa) {
                this.form.empresasCompartilhado = [];
            }
            if (!this.form.compartilhadaEmpresaEstrangeira) {
                this.form.empresasEstrangeiraCompartilhada = [];
            }

            if (!this.form.compartilhadaGrupoEconomico) {
                this.form.grupoEconomicoCompartilhado = [];
            }

            if (!this.form.compartilhadaEmpresaEstrangeira) {
                this.form.empresasEstrangeiraCompartilhada = [];
                this.form.paisEmpresaEstrangeiraCompartilhada = '';
            }

            if (!this.form.decisaoAutomatizada){
                this.form.descricaoDecisaoAutomatizada = '';
            }

            if (this.form.compartilhadaGrupoEconomico) {
                if (!this.form.grupoEconomicoCompartilhado.length) {
                    this.form.grupoEconomicoCompartilhado.push({ empresa:'', pais: '', como: ''})
                }
            }
            if (this.form.compartilhadaEmpresaEstrangeira) {
                if (!this.form.empresasEstrangeiraCompartilhada.length) {
                    this.form.empresasEstrangeiraCompartilhada.push({ empresa:'', pais: ''})
                }
            }
            if (this.form.compartilhadaEmpresa) {
                if (!this.form.empresasCompartilhado.length) {
                    this.form.empresasCompartilhado.push({ empresa:'', pais: ''})
                }
            }
        },
        changeFinalidade(){
            const finalidade = this.finalidades.find(el => el._id.toString() === this.form.finalidade.toString());
            if(finalidade && finalidade.baselegal){
                const baselegal = this.baselegais.find(el => el._id.toString() === finalidade.baselegal._id.toString());
                if(baselegal) this.form.baselegal = finalidade.baselegal._id;
                if(baselegal && baselegal.nome === "Cumprimento de obrigação legal ou regulatória") {
                    for(let i = 0; i < finalidade.legislacoes.length; i++){
                        const lesgis = finalidade.legislacoes[i];
                        const legislacao = this.legislacoes.find(el => el._id.toString() === lesgis.toString());
                        if(legislacao) this.form.legislacoes.push(legislacao);
                    }
                }
            }
        },
        novoItem(item, indexFornecedor){
            if(item === 'Ativo'){
                this.formNovo = { url: "ativos", nome: ''};
                this.$modal.show('novoItem');
            }
            if(item === 'Ameaca'){
                this.formNovo = { url: "ameacas", nome: ''};
                this.$modal.show('novoItem');
            }
            if(item === 'Vulnerabilidade'){
                this.formNovo = { url: "vulnerabilidades", nome: ''};
                this.$modal.show('novoItem');
            }
            if(item === 'Finalidade'){
                this.formNovo = { url: "finalidades", titulo: '', baselegal: null };
                this.$modal.show('novoItem');
            }
            if(item === 'Setor'){
                this.formNovo = { url: "setores", nome: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'BaseLegal'){
                this.formNovo = { url: "baselegais", nome: "", artigo: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'Tipo Titulares'){
                this.formNovo = { url: "tipotitulares", tipo: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'Canais'){
                this.formNovo = { url: "canais", nome: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'Legislacoes') {
                this.formNovo = { url: "legislacoes", nome: "", artigo: "", negocio: "", tema: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'Armazenamento') {
                this.formNovo = { url: "armazenamento", nome: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'MedidasSeguranca') {
                this.formNovo = { url: "medidasSeguranca", nome: "", descricao: "", tipo: "administrativa" };
                this.$modal.show('novoItem');
            }
            if(item === 'MedidasSegurancaTecnica') {
                this.formNovo = { url: "medidasSeguranca", nome: "", descricao: "", tipo: "tecnica" };
                this.$modal.show('novoItem');
            }
            if(item === 'PessoaNaEmpresa'){
                this.formNovo = { url: "pessoas", nome: "", email: "", password: "", setor: null };
                this.$modal.show('novoItem');
            }
            if(item === 'Dados'){
                this.formNovo = { url: "dados", nome: "", sensivel: false, critico: false, categoria: null,};
                this.$modal.show('novoItem');
            }
            if(item === 'DadosSensiveis'){
                this.formNovo = { url: "dados", nome: "", sensivel: true, critico: false, categoria: null,};
                this.$modal.show('novoItem');
            }
            if(item === 'compartilhadaGrupoEconomico'){
                this.formNovo = { indexFornecedor, item: "compartilhadaGrupoEconomico", url: "fornecedores", agente: null, nome: "", cnpj: "", responsavel: "" };
                this.$modal.show('novoItem');
            }
            if(item === 'compartilhadaEmpresa'){
                this.formNovo = { indexFornecedor, item: "compartilhadaEmpresa", url: "fornecedores", agente: null, nome: "", cnpj: "", responsavel: "" };
                this.$modal.show('novoItem');
            }
        },
        async criarItem(){
            const req = await this.$http.post(`/v1/${this.formNovo.url}`, this.formNovo);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$modal.hide('novoItem');

                if(this.formNovo.url === 'finalidades') {
                    this.finalidades.push(req.data.data);
                    this.form.finalidade.push(req.data.data);
                }

                if(this.formNovo.url === 'setores') {
                    this.setores.push(req.data.data);
                }
                if(this.formNovo.url === 'ativos') {
                    this.ativos.push(req.data.data);
                }
                if(this.formNovo.url === 'ameacas') {
                    this.ameacas.push(req.data.data);
                }

                if(this.formNovo.url === 'vulnerabilidades') {
                    this.vulnerabilidades.push(req.data.data);
                }

                if(this.formNovo.url === 'baselegais') {
                    this.baselegais.push(req.data.data);
                }
                
                if(this.formNovo.url === 'tipotitulares') {
                    this.tipotitulares.push(req.data.data);
                    this.form.tipotitular.push(req.data.data);
                }

                if(this.formNovo.url === 'canais') {
                    this.canais.push(req.data.data);
                    this.form.canais.push(req.data.data);
                }

                if(this.formNovo.url === 'legislacoes') {
                    this.legislacoes.push(req.data.data);
                    this.form.legislacoes.push(req.data.data);
                }

                if(this.formNovo.url === 'armazenamento') {
                    this.armazenamentos.push(req.data.data);
                    this.form.armazenamento.push(req.data.data);
                }

                if(this.formNovo.url === 'medidasSeguranca' && this.formNovo.tipo === 'administrativa') {
                    this.medidasSeguranca.push(req.data.data);
                    this.form.medidasSeguranca.push(req.data.data);
                }
                if(this.formNovo.url === 'medidasSeguranca' && this.formNovo.tipo === 'tecnica') {
                    this.medidasSegurancaTecnica.push(req.data.data);
                    this.form.medidasSegurancaTecnica.push(req.data.data);
                }

                if(this.formNovo.url === 'pessoas') {
                    this.pessoas.push(req.data.data);
                }

                if(this.formNovo.url === 'dados' && !this.formNovo.sensivel) {
                    this.dados.push(req.data.data);
                    this.form.dados.push(req.data.data);

                    this.onSelect(this.form.dados);
                }

                if(this.formNovo.url === 'dados' && this.formNovo.sensivel) {
                    this.dadossensiveis.push(req.data.data);
                    this.form.dadossensiveis.push(req.data.data);

                    this.onSelect(this.form.dadossensiveis);
                }

                if(this.formNovo.url === 'fornecedores' && this.formNovo.item === 'compartilhadaEmpresa') {
                    this.fornecedores.push(req.data.data);
                    this.form.empresasCompartilhado[this.formNovo.indexFornecedor].empresa = req.data.data._id;
                    if(req.data.data.agente) this.form.empresasCompartilhado[this.formNovo.indexFornecedor].agente = req.data.data.agente;
                }

                if(this.formNovo.url === 'fornecedores' && this.formNovo.item === 'compartilhadaGrupoEconomico') {
                    this.fornecedores.push(req.data.data);
                    this.form.grupoEconomicoCompartilhado[this.formNovo.indexFornecedor].empresa = req.data.data._id;
                    if(req.data.data.agente) this.form.grupoEconomicoCompartilhado[this.formNovo.indexFornecedor].agente = req.data.data.agente;
                }

            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        changePessoaResponsavel () {
            if(this.form.pessoa_responsavelQMA && this.form.tipo_responsavelQMA === 'pessoa') {
                this.form.responsavel = this.form.pessoa_responsavelQMA;
            }
        },
        changeAgenteEmpresa (item) {
            const empresa = this.fornecedores.find(el => el.nome.toString() === item.empresa.toString() || el._id.toString() === item.empresa.toString());
            if(empresa){
                item.agente = empresa.agente._id;
            }
        },
        async findBancoDeAtividades(){
            const listpreatividade = await this.$http.post(`/v1/preatividade/list`, { all: true, setor: this.preatividade_setor});
            this.preatividades = listpreatividade.data.data;
        },
        async excluirDefinitivamente(){
            this.$confirm({
                title: 'Excluir definitivamente',
                message: `Deseja excluir a atividade selecionada dos registros?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if(!confirm) return;
                    const id = this.$route.params.id;
                    await this.$http.delete(`/v1/${this.route_backend}/excluir/${id}`);
                    this.$vToastify.success("Removido com sucesso!");
                    this.$router.back();
                }
            })
        }
    },
    async beforeMount() {
        let loader = this.$loading.show({
            container: null,
            canCancel: true
        });

        const listDados = await this.$http.post(`/v1/dados/list`, { all: true, normal_e_critico: true });
        this.dados = listDados.data.data;

        const listDadosS = await this.$http.post(`/v1/dados/list`, { all: true, sensivel: true });
        this.dadossensiveis = listDadosS.data.data;

        const listcatdados = await this.$http.post(`/v1/categoriadados/list`, { all: true });
        this.categoriasdados = listcatdados.data.data;

        const listTitulares = await this.$http.post(`/v1/tipotitulares/list`, { all: true});
        this.tipotitulares = listTitulares.data.data;

        const listCanais = await this.$http.post(`/v1/canais/list`, { all: true});
        this.canais = listCanais.data.data;

        const listBaselegais = await this.$http.post(`/v1/baselegais/list`, { all: true});
        this.baselegais = listBaselegais.data.data;

        const listLegislacoes = await this.$http.post(`/v1/legislacoes/list`, { all: true});
        this.legislacoes = listLegislacoes.data.data;

        const listCategorias = await this.$http.post('/v1/categoriadados/list', { all: true });
        this.categorias = listCategorias.data.data;

        const listArmazenamento = await this.$http.post(`/v1/armazenamento/list`, { all: true});
        this.armazenamentos = listArmazenamento.data.data;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;

        const profissionaisRef = await this.$http.post(`/v1/profissionais/listProfissionaisEmpresa`);  
        this.profissionais = profissionaisRef.data.data;

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true});
        this.fornecedores = listFornecedores.data.data; 

        const listEmpresas = await this.$http.post(`/v1/empresas/list`, { all: true});
        this.empresas = listEmpresas.data.data;

        const listfinalidades = await this.$http.post(`/v1/finalidades/list`, { all: true});
        this.finalidades = listfinalidades.data.data;

        const listMedidas = await this.$http.post(`/v1/medidasSeguranca/list`, { all: true, administrativa: true });
        this.medidasSeguranca = listMedidas.data.data;

        const listMedidasT = await this.$http.post(`/v1/medidasSeguranca/list`, { all: true, tecnica: true });
        this.medidasSegurancaTecnica = listMedidasT.data.data;

        const listAgentesTratamento = await this.$http.post(`/v1/agentes/list`, { all: true});
        this.agentesTratamento = listAgentesTratamento.data.data;

        const ativos = await this.$http.post(`/v1/ativos/list`, { all: true});
        this.ativos = ativos.data.data;

        const ameacas = await this.$http.post(`/v1/ameacas/list`, { all: true});
        this.ameacas = ameacas.data.data;

        const vulnerabilidades = await this.$http.post(`/v1/vulnerabilidades/list`, { all: true});
        this.vulnerabilidades = vulnerabilidades.data.data;

        const condutasMitigacaoReq = await this.$http.post(`/v1/condutasmitigacao/list`,{ all: true});
        this.condutasMitigacao = condutasMitigacaoReq.data.data;

        const reqForProPess = await this.$http.post(`/v1/diagnosticoAplicado/forpropess`)
        this.agentesProfissionaisPessoas = reqForProPess.data

        await this.findBancoDeAtividades();

        const id = this.$route.params.id;
        if (id) {
            const req = await this.$http.get(`/v1/${this.route_backend}/${id}`);
            this.form = req.data;

            if(!this.form.ativo) this.inicialmenteDesativada = true;

            if(!this.form.pai.length) this.form.pai.push(JSON.parse(JSON.stringify(this.pai)));

            for(let i =0;i<this.form.pai.length;i++){
                if(this.form.pai[i].prazoDeExecucao && this.form.pai[i].prazoDeExecucao.de){
                    this.form.pai[i].prazoDeExecucao.de = moment(this.form.pai[i].prazoDeExecucao.de).format("YYYY-MM-DD")
                }
                if(this.form.pai[i].prazoDeExecucao && this.form.pai[i].prazoDeExecucao.ate){
                    this.form.pai[i].prazoDeExecucao.ate = moment(this.form.pai[i].prazoDeExecucao.ate).format("YYYY-MM-DD")
                } 
            }
            
            this.onSelect(this.form.dados);
            this.onSelect(this.form.dadossensiveis);

            this.updateTratamentodados();

            if (!this.form.principiosAtendidos || !this.form.principiosAtendidos.length || this.form.principiosAtendidos.length < 10) {
                this.form.principiosAtendidos = 
                [
                    {
                        "entende": false,
                        "principio": "I",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "II",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "III",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "IV",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "V",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VI",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VII",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VIII",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "IX",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "X",
                        "obs": ""
                    },
                ]
            }

            if (!this.form.microatividades || !this.form.microatividades.length) {
                this.form.microatividades = []
            }

            if (!this.form.baselegal || !this.form.baselegal.length) {
                this.form.baselegal = []
            }

            if (this.form.dataAtualizacao) {
                this.form.dataAtualizacao = moment(this.form.dataAtualizacao).format("YYYY-MM-DD");
            }

            this.updateQtdTipoTitular();
        }

        loader.hide();
    },
}
</script>
<style>
/* Ajusta o tamanho da pré-visualização do arquivo */
#doc .dz-preview {
    width: 80px !important;
    height: 80px !important;
    min-height: 80px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

#doc .dz-remove {
    color: red !important;
    border: 2px solid red !important;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 4px;
    position:top,
}

#doc .dz-filename {
    color: black !important;
    font-size: 10px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
</style>